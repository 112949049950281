








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class OrganizationListPage extends Vue {
  @Prop() id: string;
}

