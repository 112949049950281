export default [
  {
    label: 'Afghanistan',
    value: 'AF',
    latitude: 33,
    longitude: 65,
  },
  {
    label: 'Åland Islands',
    value: 'AX',
    latitude: 60.15,
    longitude: 20,
  },
  {
    label: 'Albania',
    value: 'AL',
    latitude: 41,
    longitude: 20,
  },
  {
    label: 'Algeria',
    value: 'DZ',
    latitude: 28,
    longitude: 3,
  },
  {
    label: 'American Samoa',
    value: 'AS',
    latitude: -14.3333,
    longitude: -170,
  },
  {
    label: 'Andorra',
    value: 'AD',
    latitude: 42.5,
    longitude: 1.5,
  },
  {
    label: 'Angola',
    value: 'AO',
    latitude: -12.5,
    longitude: 18.5,
  },
  {
    label: 'Anguilla',
    value: 'AI',
    latitude: 18.25,
    longitude: -63.1667,
  },
  {
    label: 'Antarctica',
    value: 'AQ',
    latitude: -90,
    longitude: 0,
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
    latitude: 17.05,
    longitude: -61.8,
  },
  {
    label: 'Argentina',
    value: 'AR',
    latitude: -34,
    longitude: -64,
  },
  {
    label: 'Armenia',
    value: 'AM',
    latitude: 40,
    longitude: 45,
  },
  {
    label: 'Aruba',
    value: 'AW',
    latitude: 12.5,
    longitude: -69.9667,
  },
  {
    label: 'Australia',
    value: 'AU',
    latitude: -27,
    longitude: 133,
  },
  {
    label: 'Austria',
    value: 'AT',
    latitude: 47.3333,
    longitude: 13.3333,
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
    latitude: 40.5,
    longitude: 47.5,
  },
  {
    label: 'Bahamas',
    value: 'BS',
    latitude: 24.25,
    longitude: -76,
  },
  {
    label: 'Bahrain',
    value: 'BH',
    latitude: 26,
    longitude: 50.55,
  },
  {
    label: 'Bangladesh',
    value: 'BD',
    latitude: 24,
    longitude: 90,
  },
  {
    label: 'Barbados',
    value: 'BB',
    latitude: 13.1667,
    longitude: -59.5333,
  },
  {
    label: 'Belarus',
    value: 'BY',
    latitude: 53,
    longitude: 28,
  },
  {
    label: 'Belgium',
    value: 'BE',
    latitude: 50.8333,
    longitude: 4,
  },
  {
    label: 'Belize',
    value: 'BZ',
    latitude: 17.25,
    longitude: -88.75,
  },
  {
    label: 'Benin',
    value: 'BJ',
    latitude: 9.5,
    longitude: 2.25,
  },
  {
    label: 'Bermuda',
    value: 'BM',
    latitude: 32.3333,
    longitude: -64.75,
  },
  {
    label: 'Bhutan',
    value: 'BT',
    latitude: 27.5,
    longitude: 90.5,
  },
  {
    label: 'Bolivia, Plurinational State of',
    value: 'BO',
    latitude: -17,
    longitude: -65,
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    latitude: 17.48268,
    longitude: -62.98324,
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    latitude: 44,
    longitude: 18,
  },
  {
    label: 'Botswana',
    value: 'BW',
    latitude: -22,
    longitude: 24,
  },
  {
    label: 'Bouvet Island',
    value: 'BV',
    latitude: -54.4333,
    longitude: 3.4,
  },
  {
    label: 'Brazil',
    value: 'BR',
    latitude: -10,
    longitude: -55,
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
    latitude: -6,
    longitude: 71.5,
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
    latitude: 4.5,
    longitude: 114.6667,
  },
  {
    label: 'Bulgaria',
    value: 'BG',
    latitude: 43,
    longitude: 25,
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    latitude: 13,
    longitude: -2,
  },
  {
    label: 'Burundi',
    value: 'BI',
    latitude: -3.5,
    longitude: 30,
  },
  {
    label: 'Cambodia',
    value: 'KH',
    latitude: 13,
    longitude: 105,
  },
  {
    label: 'Cameroon',
    value: 'CM',
    latitude: 6,
    longitude: 12,
  },
  {
    label: 'Canada',
    value: 'CA',
    latitude: 60,
    longitude: -95,
  },
  {
    label: 'Cape Verde',
    value: 'CV',
    latitude: 16,
    longitude: -24,
  },
  {
    label: 'Cayman Islands',
    value: 'KY',
    latitude: 19.5,
    longitude: -80.5,
  },
  {
    label: 'Central African Republic',
    value: 'CF',
    latitude: 7,
    longitude: 21,
  },
  {
    label: 'Chad',
    value: 'TD',
    latitude: 15,
    longitude: 19,
  },
  {
    label: 'Chile',
    value: 'CL',
    latitude: -30,
    longitude: -71,
  },
  {
    label: 'China',
    value: 'CN',
    latitude: 35,
    longitude: 105,
  },
  {
    label: 'Christmas Island',
    value: 'CX',
    latitude: -10.5,
    longitude: 105.6667,
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
    latitude: -12.5,
    longitude: 96.8333,
  },
  {
    label: 'Colombia',
    value: 'CO',
    latitude: 4,
    longitude: -72,
  },
  {
    label: 'Comoros',
    value: 'KM',
    latitude: -12.1667,
    longitude: 44.25,
  },
  {
    label: 'Congo',
    value: 'CG',
    latitude: -1,
    longitude: 15,
  },
  {
    label: 'Congo, the Democratic Republic of the',
    value: 'CD',
    latitude: 0,
    longitude: 25,
  },
  {
    label: 'Cook Islands',
    value: 'CK',
    latitude: -21.2333,
    longitude: -159.7667,
  },
  {
    label: 'Costa Rica',
    value: 'CR',
    latitude: 10,
    longitude: -84,
  },
  {
    label: "Côte d'Ivoire",
    value: 'CI',
    latitude: 8,
    longitude: -5,
  },
  {
    label: 'Croatia',
    value: 'HR',
    latitude: 45.1667,
    longitude: 15.5,
  },
  {
    label: 'Cuba',
    value: 'CU',
    latitude: 21.5,
    longitude: -80,
  },
  {
    label: 'Curaçao',
    value: 'CW',
    latitude: 12.1833,
    longitude: -68.9333,
  },
  {
    label: 'Cyprus',
    value: 'CY',
    latitude: 35,
    longitude: 33,
  },
  {
    label: 'Czech Republic',
    value: 'CZ',
    latitude: 49.75,
    longitude: 15.5,
  },
  {
    label: 'Denmark',
    value: 'DK',
    latitude: 56,
    longitude: 10,
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    latitude: 11.5,
    longitude: 43,
  },
  {
    label: 'Dominica',
    value: 'DM',
    latitude: 15.4167,
    longitude: -61.3333,
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
    latitude: 19,
    longitude: -70.6667,
  },
  {
    label: 'Ecuador',
    value: 'EC',
    latitude: -2,
    longitude: -77.5,
  },
  {
    label: 'Egypt',
    value: 'EG',
    latitude: 27,
    longitude: 30,
  },
  {
    label: 'El Salvador',
    value: 'SV',
    latitude: 13.8333,
    longitude: -88.9167,
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
    latitude: 2,
    longitude: 10,
  },
  {
    label: 'Eritrea',
    value: 'ER',
    latitude: 15,
    longitude: 39,
  },
  {
    label: 'Estonia',
    value: 'EE',
    latitude: 59,
    longitude: 26,
  },
  {
    label: 'Ethiopia',
    value: 'ET',
    latitude: 8,
    longitude: 38,
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK',
    latitude: -51.75,
    longitude: -59,
  },
  {
    label: 'Faroe Islands',
    value: 'FO',
    latitude: 62,
    longitude: -7,
  },
  {
    label: 'Fiji',
    value: 'FJ',
    latitude: -18,
    longitude: 175,
  },
  {
    label: 'Finland',
    value: 'FI',
    latitude: 64,
    longitude: 26,
  },
  {
    label: 'France',
    value: 'FR',
    latitude: 46,
    longitude: 2,
  },
  {
    label: 'French Guiana',
    value: 'GF',
    latitude: 4,
    longitude: -53,
  },
  {
    label: 'French Polynesia',
    value: 'PF',
    latitude: -15,
    longitude: -140,
  },
  {
    label: 'French Southern Territories',
    value: 'TF',
    latitude: -43,
    longitude: 67,
  },
  {
    label: 'Gabon',
    value: 'GA',
    latitude: -1,
    longitude: 11.75,
  },
  {
    label: 'Gambia',
    value: 'GM',
    latitude: 13.4667,
    longitude: -16.5667,
  },
  {
    label: 'Georgia',
    value: 'GE',
    latitude: 42,
    longitude: 43.5,
  },
  {
    label: 'Germany',
    value: 'DE',
    latitude: 51,
    longitude: 9,
  },
  {
    label: 'Ghana',
    value: 'GH',
    latitude: 8,
    longitude: -2,
  },
  {
    label: 'Gibraltar',
    value: 'GI',
    latitude: 36.1833,
    longitude: -5.3667,
  },
  {
    label: 'Greece',
    value: 'GR',
    latitude: 39,
    longitude: 22,
  },
  {
    label: 'Greenland',
    value: 'GL',
    latitude: 72,
    longitude: -40,
  },
  {
    label: 'Grenada',
    value: 'GD',
    latitude: 12.1167,
    longitude: -61.6667,
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
    latitude: 16.25,
    longitude: -61.5833,
  },
  {
    label: 'Guam',
    value: 'GU',
    latitude: 13.4667,
    longitude: 144.7833,
  },
  {
    label: 'Guatemala',
    value: 'GT',
    latitude: 15.5,
    longitude: -90.25,
  },
  {
    label: 'Guernsey',
    value: 'GG',
    latitude: 49.45,
    longitude: -2.58,
  },
  {
    label: 'Guinea',
    value: 'GN',
    latitude: 11,
    longitude: -10,
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
    latitude: 12,
    longitude: -15,
  },
  {
    label: 'Guyana',
    value: 'GY',
    latitude: 5,
    longitude: -59,
  },
  {
    label: 'Haiti',
    value: 'HT',
    latitude: 19,
    longitude: -72.4167,
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'HM',
    latitude: -53.1,
    longitude: 72.5167,
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA',
    latitude: 41.9,
    longitude: 12.45,
  },
  {
    label: 'Honduras',
    value: 'HN',
    latitude: 15,
    longitude: -86.5,
  },
  {
    label: 'Hong Kong',
    value: 'HK',
    latitude: 22.25,
    longitude: 114.1667,
  },
  {
    label: 'Hungary',
    value: 'HU',
    latitude: 47,
    longitude: 20,
  },
  {
    label: 'Iceland',
    value: 'IS',
    latitude: 65,
    longitude: -18,
  },
  {
    label: 'India',
    value: 'IN',
    latitude: 20,
    longitude: 77,
  },
  {
    label: 'Indonesia',
    value: 'ID',
    latitude: -5,
    longitude: 120,
  },
  {
    label: 'Iran, Islamic Republic of',
    value: 'IR',
    latitude: 32,
    longitude: 53,
  },
  {
    label: 'Iraq',
    value: 'IQ',
    latitude: 33,
    longitude: 44,
  },
  {
    label: 'Ireland',
    value: 'IE',
    latitude: 53,
    longitude: -8,
  },
  {
    label: 'Isle of Man',
    value: 'IM',
    latitude: 54.23,
    longitude: -4.57,
  },
  {
    label: 'Israel',
    value: 'IL',
    latitude: 31.5,
    longitude: 34.75,
  },
  {
    label: 'Italy',
    value: 'IT',
    latitude: 42.8333,
    longitude: 12.8333,
  },
  {
    label: 'Jamaica',
    value: 'JM',
    latitude: 18.25,
    longitude: -77.5,
  },
  {
    label: 'Japan',
    value: 'JP',
    latitude: 36,
    longitude: 138,
  },
  {
    label: 'Jersey',
    value: 'JE',
    latitude: 49.2167,
    longitude: -2.1167,
  },
  {
    label: 'Jordan',
    value: 'JO',
    latitude: 31,
    longitude: 36,
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    latitude: 48,
    longitude: 68,
  },
  {
    label: 'Kenya',
    value: 'KE',
    latitude: 1,
    longitude: 38,
  },
  {
    label: 'Kiribati',
    value: 'KI',
    latitude: 1.4167,
    longitude: 173,
  },
  {
    label: "Korea, Democratic People's Republic of",
    value: 'KP',
    latitude: 40,
    longitude: 127,
  },
  {
    label: 'Korea, Republic of',
    value: 'KR',
    latitude: 37,
    longitude: 127.5,
  },
  {
    label: 'Kuwait',
    value: 'KW',
    latitude: 29.3375,
    longitude: 47.6581,
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
    latitude: 41,
    longitude: 75,
  },
  {
    label: "Lao People's Democratic Republic",
    value: 'LA',
    latitude: 18,
    longitude: 105,
  },
  {
    label: 'Latvia',
    value: 'LV',
    latitude: 57,
    longitude: 25,
  },
  {
    label: 'Lebanon',
    value: 'LB',
    latitude: 33.8333,
    longitude: 35.8333,
  },
  {
    label: 'Lesotho',
    value: 'LS',
    latitude: -29.5,
    longitude: 28.5,
  },
  {
    label: 'Liberia',
    value: 'LR',
    latitude: 6.5,
    longitude: -9.5,
  },
  {
    label: 'Libya',
    value: 'LY',
    latitude: 25,
    longitude: 17,
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
    latitude: 47.1667,
    longitude: 9.5333,
  },
  {
    label: 'Lithuania',
    value: 'LT',
    latitude: 56,
    longitude: 24,
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    latitude: 49.75,
    longitude: 6.1667,
  },
  {
    label: 'Macao',
    value: 'MO',
    latitude: 22.1667,
    longitude: 113.55,
  },
  {
    label: 'Macedonia, the Former Yugoslav Republic of',
    value: 'MK',
    latitude: 41.8333,
    longitude: 22,
  },
  {
    label: 'Madagascar',
    value: 'MG',
    latitude: -20,
    longitude: 47,
  },
  {
    label: 'Malawi',
    value: 'MW',
    latitude: -13.5,
    longitude: 34,
  },
  {
    label: 'Malaysia',
    value: 'MY',
    latitude: 2.5,
    longitude: 112.5,
  },
  {
    label: 'Maldives',
    value: 'MV',
    latitude: 3.25,
    longitude: 73,
  },
  {
    label: 'Mali',
    value: 'ML',
    latitude: 17,
    longitude: -4,
  },
  {
    label: 'Malta',
    value: 'MT',
    latitude: 35.8333,
    longitude: 14.5833,
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
    latitude: 9,
    longitude: 168,
  },
  {
    label: 'Martinique',
    value: 'MQ',
    latitude: 14.6667,
    longitude: -61,
  },
  {
    label: 'Mauritania',
    value: 'MR',
    latitude: 20,
    longitude: -12,
  },
  {
    label: 'Mauritius',
    value: 'MU',
    latitude: -20.2833,
    longitude: 57.55,
  },
  {
    label: 'Mayotte',
    value: 'YT',
    latitude: -12.8333,
    longitude: 45.1667,
  },
  {
    label: 'Mexico',
    value: 'MX',
    latitude: 23,
    longitude: -102,
  },
  {
    label: 'Micronesia, Federated States of',
    value: 'FM',
    latitude: 6.9167,
    longitude: 158.25,
  },
  {
    label: 'Moldova, Republic of',
    value: 'MD',
    latitude: 47,
    longitude: 29,
  },
  {
    label: 'Monaco',
    value: 'MC',
    latitude: 43.7333,
    longitude: 7.4,
  },
  {
    label: 'Mongolia',
    value: 'MN',
    latitude: 46,
    longitude: 105,
  },
  {
    label: 'Montenegro',
    value: 'ME',
    latitude: 42,
    longitude: 19,
  },
  {
    label: 'Montserrat',
    value: 'MS',
    latitude: 16.75,
    longitude: -62.2,
  },
  {
    label: 'Morocco',
    value: 'MA',
    latitude: 32,
    longitude: -5,
  },
  {
    label: 'Mozambique',
    value: 'MZ',
    latitude: -18.25,
    longitude: 35,
  },
  {
    label: 'Myanmar',
    value: 'MM',
    latitude: 22,
    longitude: 98,
  },
  {
    label: 'Namibia',
    value: 'NA',
    latitude: -22,
    longitude: 17,
  },
  {
    label: 'Nauru',
    value: 'NR',
    latitude: -0.5333,
    longitude: 166.9167,
  },
  {
    label: 'Nepal',
    value: 'NP',
    latitude: 28,
    longitude: 84,
  },
  {
    label: 'Netherlands',
    value: 'NL',
    latitude: 52.5,
    longitude: 5.75,
  },
  {
    label: 'New Caledonia',
    value: 'NC',
    latitude: -21.5,
    longitude: 165.5,
  },
  {
    label: 'New Zealand',
    value: 'NZ',
    latitude: -41,
    longitude: 174,
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    latitude: 13,
    longitude: -85,
  },
  {
    label: 'Niger',
    value: 'NE',
    latitude: 16,
    longitude: 8,
  },
  {
    label: 'Nigeria',
    value: 'NG',
    latitude: 10,
    longitude: 8,
  },
  {
    label: 'Niue',
    value: 'NU',
    latitude: -19.0333,
    longitude: -169.8667,
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
    latitude: -29.0333,
    longitude: 167.95,
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
    latitude: 15.2,
    longitude: 145.75,
  },
  {
    label: 'Norway',
    value: 'NO',
    latitude: 62,
    longitude: 10,
  },
  {
    label: 'Oman',
    value: 'OM',
    latitude: 21,
    longitude: 57,
  },
  {
    label: 'Pakistan',
    value: 'PK',
    latitude: 30,
    longitude: 70,
  },
  {
    label: 'Palau',
    value: 'PW',
    latitude: 7.5,
    longitude: 134.5,
  },
  {
    label: 'Palestine, State of',
    value: 'PS',
    latitude: 32,
    longitude: 35.25,
  },
  {
    label: 'Panama',
    value: 'PA',
    latitude: 9,
    longitude: -80,
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
    latitude: -6,
    longitude: 147,
  },
  {
    label: 'Paraguay',
    value: 'PY',
    latitude: -23,
    longitude: -58,
  },
  {
    label: 'Peru',
    value: 'PE',
    latitude: -10,
    longitude: -76,
  },
  {
    label: 'Philippines',
    value: 'PH',
    latitude: 13,
    longitude: 122,
  },
  {
    label: 'Pitcairn',
    value: 'PN',
    latitude: -24.36146,
    longitude: -128.3163761,
  },
  {
    label: 'Poland',
    value: 'PL',
    latitude: 52,
    longitude: 20,
  },
  {
    label: 'Portugal',
    value: 'PT',
    latitude: 39.5,
    longitude: -8,
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
    latitude: 18.25,
    longitude: -66.5,
  },
  {
    label: 'Qatar',
    value: 'QA',
    latitude: 25.5,
    longitude: 51.25,
  },
  {
    label: 'Réunion',
    value: 'RE',
    latitude: -21.1,
    longitude: 55.6,
  },
  {
    label: 'Romania',
    value: 'RO',
    latitude: 46,
    longitude: 25,
  },
  {
    label: 'Russian Federation',
    value: 'RU',
    latitude: 60,
    longitude: 100,
  },
  {
    label: 'Rwanda',
    value: 'RW',
    latitude: -2,
    longitude: 30,
  },
  {
    label: 'Saint Barthélemy',
    value: 'BL',
    latitude: 17.9,
    longitude: -62.8333,
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
    latitude: -15.9333,
    longitude: -5.7,
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
    latitude: 17.3333,
    longitude: -62.75,
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
    latitude: 13.8833,
    longitude: -61.1333,
  },
  {
    label: 'Saint Martin (French part)',
    value: 'MF',
    latitude: 18.05,
    longitude: -63.08,
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
    latitude: 46.8333,
    longitude: -56.3333,
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
    latitude: 13.25,
    longitude: -61.2,
  },
  {
    label: 'Samoa',
    value: 'WS',
    latitude: -13.5833,
    longitude: -172.3333,
  },
  {
    label: 'San Marino',
    value: 'SM',
    latitude: 43.7667,
    longitude: 12.4167,
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
    latitude: 1,
    longitude: 7,
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
    latitude: 25,
    longitude: 45,
  },
  {
    label: 'Senegal',
    value: 'SN',
    latitude: 14,
    longitude: -14,
  },
  {
    label: 'Serbia',
    value: 'RS',
    latitude: 44,
    longitude: 21,
  },
  {
    label: 'Seychelles',
    value: 'SC',
    latitude: -4.5833,
    longitude: 55.6667,
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    latitude: 8.5,
    longitude: -11.5,
  },
  {
    label: 'Singapore',
    value: 'SG',
    latitude: 1.3667,
    longitude: 103.8,
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'SX',
    latitude: 18.05,
    longitude: -63.08,
  },
  {
    label: 'Slovakia',
    value: 'SK',
    latitude: 48.6667,
    longitude: 19.5,
  },
  {
    label: 'Slovenia',
    value: 'SI',
    latitude: 46,
    longitude: 15,
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
    latitude: -8,
    longitude: 159,
  },
  {
    label: 'Somalia',
    value: 'SO',
    latitude: 10,
    longitude: 49,
  },
  {
    label: 'South Africa',
    value: 'ZA',
    latitude: -29,
    longitude: 24,
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    latitude: -54.5,
    longitude: -37,
  },
  {
    label: 'South Sudan',
    value: 'SS',
    latitude: 4.85,
    longitude: 31.6,
  },
  {
    label: 'Spain',
    value: 'ES',
    latitude: 40,
    longitude: -4,
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    latitude: 7,
    longitude: 81,
  },
  {
    label: 'Sudan',
    value: 'SD',
    latitude: 15,
    longitude: 30,
  },
  {
    label: 'Suriname',
    value: 'SR',
    latitude: 4,
    longitude: -56,
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
    latitude: 78,
    longitude: 20,
  },
  {
    label: 'Swaziland',
    value: 'SZ',
    latitude: -26.5,
    longitude: 31.5,
  },
  {
    label: 'Sweden',
    value: 'SE',
    latitude: 62,
    longitude: 15,
  },
  {
    label: 'Switzerland',
    value: 'CH',
    latitude: 47,
    longitude: 8,
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY',
    latitude: 35,
    longitude: 38,
  },
  {
    label: 'Taiwan, Province of China',
    value: 'TW',
    latitude: 23.5,
    longitude: 121,
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
    latitude: 39,
    longitude: 71,
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'TZ',
    latitude: -6,
    longitude: 35,
  },
  {
    label: 'Thailand',
    value: 'TH',
    latitude: 15,
    longitude: 100,
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
    latitude: -8.5,
    longitude: 125.55,
  },
  {
    label: 'Togo',
    value: 'TG',
    latitude: 8,
    longitude: 1.1667,
  },
  {
    label: 'Tokelau',
    value: 'TK',
    latitude: -9,
    longitude: -172,
  },
  {
    label: 'Tonga',
    value: 'TO',
    latitude: -20,
    longitude: -175,
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
    latitude: 11,
    longitude: -61,
  },
  {
    label: 'Tunisia',
    value: 'TN',
    latitude: 34,
    longitude: 9,
  },
  {
    label: 'Turkey',
    value: 'TR',
    latitude: 39,
    longitude: 35,
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
    latitude: 40,
    longitude: 60,
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'TC',
    latitude: 21.75,
    longitude: -71.5833,
  },
  {
    label: 'Tuvalu',
    value: 'TV',
    latitude: -8,
    longitude: 178,
  },
  {
    label: 'Uganda',
    value: 'UG',
    latitude: 1,
    longitude: 32,
  },
  {
    label: 'Ukraine',
    value: 'UA',
    latitude: 49,
    longitude: 32,
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    latitude: 24,
    longitude: 54,
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    latitude: 54,
    longitude: -2,
  },
  {
    label: 'United States',
    value: 'US',
    latitude: 38,
    longitude: -97,
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM',
    latitude: 19.2833,
    longitude: 166.6,
  },
  {
    label: 'Uruguay',
    value: 'UY',
    latitude: -33,
    longitude: -56,
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
    latitude: 41,
    longitude: 64,
  },
  {
    label: 'Vanuatu',
    value: 'VU',
    latitude: -16,
    longitude: 167,
  },
  {
    label: 'Venezuela, Bolivarian Republic of',
    value: 'VE',
    latitude: 8,
    longitude: -66,
  },
  {
    label: 'Viet Nam',
    value: 'VN',
    latitude: 16,
    longitude: 106,
  },
  {
    label: 'Virgin Islands, British',
    value: 'VG',
    latitude: 18.5,
    longitude: -64.5,
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'VI',
    latitude: 18.3333,
    longitude: -64.8333,
  },
  {
    label: 'Wallis and Futuna',
    value: 'WF',
    latitude: -13.3,
    longitude: -176.2,
  },
  {
    label: 'Western Sahara',
    value: 'EH',
    latitude: 24.5,
    longitude: -13,
  },
  {
    label: 'Yemen',
    value: 'YE',
    latitude: 15,
    longitude: 48,
  },
  {
    label: 'Zambia',
    value: 'ZM',
    latitude: -15,
    longitude: 30,
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    latitude: -20,
    longitude: 30,
  },
]
