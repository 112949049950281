import { authGuard } from '@/auth'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OrganizationListPage from '../views/OrganizationListPage.vue'
import AdminTempPage from '../views/AdminTempPage.vue'
import OrganizationDetailsPage from '../views/OrganizationDetailsPage.vue'
import Login from '../views/Login.vue'
import Store from '../store'
import PasswordReset from '../views/PasswordReset.vue'
import DownloadsPage from '../views/DownloadsPage.vue'

Vue.use(VueRouter)

const auth = { beforeEnter: authGuard }

const resetPassword = async function (to, from, next) {
  const hash = to.params.hash
  await Store.dispatch('resetPassword', hash)
  // next('/login')
  next()
}

const routes = [
  { ...auth, path: '/', component: Home },
  { ...auth, path: '/microsoft-login/', component: Home },
  { ...auth, path: '/organizations/:id', component: OrganizationListPage, props: true, meta: { permission: 'query.organization.organizations' } },
  { ...auth, path: '/admin-temp', component: AdminTempPage, meta: { permission: 'query.organization.organizations' } },
  { ...auth, path: '/organizations/:id/details', component: OrganizationDetailsPage, props: true },
  { ...auth, path: '/organizations/new/:id', component: OrganizationDetailsPage, props: true },
  { ...auth, path: '/downloads', component: DownloadsPage },
  {
    ...auth,
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      hasMenuBar: false,
    },
  },
  {
    beforeEnter: resetPassword,
    path: '/reset-password/:hash',
    component: PasswordReset,
    meta: {
      hasMenuBar: false,
    },
  },
  { ...auth, path: '*', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.onError(err => {
  console.error('router.onError:', err)
})

export default router
