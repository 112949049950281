























import { sdk } from '@/api'
import { OrganizationSchema } from '@/generated/sdk'
import { format, isEqual, startOfMonth, subMonths } from 'date-fns'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AdminTempPage extends Vue {
  filterText: string = null;

  // The three data, will be filled with the organizations
  treeData: OrganizationSchema[] = [];

  // Used when traversing tree nodes
  defaultProps = {
    children: 'organizations',
    label: function (node: OrganizationSchema): string {
      const { name, type } = node
      const date = format(new Date(parseInt(node.createdAt)), 'dd/MM/yyyy')
      const nUsers = node.users.length
      const nNewUsersPreviousMonth = node.users.filter(function (u) {
        const prevMonth = startOfMonth(subMonths(new Date(), 1))
        const createdMonth = startOfMonth(new Date(parseInt(u.createdAt)))
        return isEqual(prevMonth, createdMonth)
      }).length
      return `${name} (${type}, ${date}) [${nUsers} (+${nNewUsersPreviousMonth})])`
    },
  };

  @Watch('filterText')
  onNewValue(value: string): void {
    (this.$refs.tree as any).filter(value)
  }

  filterNode(value: string, node: OrganizationSchema): boolean {
    if (!value) return true
    return (
      node.name?.toLowerCase().indexOf(value) >= 0 ||
      node.reference?.toLowerCase().indexOf(value) >= 0 ||
      !!node.users?.find((u) => u.email?.toLowerCase().indexOf(value) >= 0)
    )
  }

  async b2bExport(): Promise<void> {
    const file = await sdk().b2bExport()
    window.open(file.b2bExport.downloadLocation)
  }

  async created(): Promise<void> {
    const data = await sdk().adminTree()
    this.treeData = data.organization as OrganizationSchema[]
  }

  async handleNodeClick(node: OrganizationSchema): Promise<void> {
    // console.log(node)
  }
}
