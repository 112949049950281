export default {
  state: () => {
    return {
      messages: [],
    }
  },

  getters: {
    messages(state) {
      return state.messages
    },
  },

  mutations: {
    errorMessage(state, value) {
      state.messages.push(value)
    },
    successMessage(state, value) {
      state.messages.push({
        extensions: {
          status: 200,
        },
        message: value,
      })
    },
  },

  actions: {
  },
}
