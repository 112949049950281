












































import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import { RoleSchema, OrganizationSchema, UserSchema } from '@/generated/sdk'
import { Getter, Action, Mutation } from 'vuex-class'
import { ADMIN_ROLE } from '../store/organizations'

@Component
export default class AdminTable extends Vue {
  @Getter queriedUsers: UserSchema[]
  @Getter organizationToEdit: OrganizationSchema
  @Getter admins: UserSchema[]
  @Getter roles: RoleSchema[]

  @Action searchUsers
  @Action getAdmins
  @Action getRoles
  @Action updateUser
  @Action removeRole

  @Mutation('queriedUsers') setAdminSearch

  admin: UserSchema = null

  created() {
    this.fetchData()
    this.resetSearch()
  }

  @Watch('organizationToEdit')
  fetchData() {
    if (this.organizationToEdit.id == null) {
      return
    }
    this.getRoles()
    this.getAdmins({ organizationId: this.organizationToEdit.id })
  }

  @Watch('organizationToEdit')
  resetSearch() {
    this.setAdminSearch([])
  }

  async addAdmin() {
    const user = this.admin
    this.admin = null
    user.roles = [this.roles.find(r => r.name === ADMIN_ROLE)]
    await this.updateUser(user)
    await this.getAdmins({ organizationId: this.organizationToEdit.id })
  }

  async removeAdmin(admin: UserSchema): Promise<void> {
    const adminRole = this.roles.find(r => r.name === ADMIN_ROLE)
    await this.removeRole({
      userId: admin.id,
      roleId: adminRole.id,
    })
    await this.getAdmins({ organizationId: this.organizationToEdit.id })
  }

  async findUser(query: string) {
    await this.searchUsers({
      organizationId: this.organizationToEdit.id,
      query,
    })
  }
}

