





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import NumberInput from '@/components/NumberInput.vue'
@Component({
  components: { NumberInput },
})
export default class ManageBilling extends Vue {
  @Getter organizationToEdit
  @Getter me

  canEditBilling() {
    return this.organizationToEdit.id === this.me.organization.id
  }

  // TODO: hacky, making sure VPN is before CyberALarm...
  get products() {
    return this.organizationToEdit.products.sort((a, b) => {
      if (a.name < b.name) {
        return 1
      } else if (a.name > b.name) {
        return -1
      } else {
        return 0
      }
    })
  }

  saveBilling() {
    this.$emit('upsert', this.organizationToEdit)
  }
}
