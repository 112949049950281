























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { childMap } from '@/store/organizations'

@Component
export default class OrganizationDetailsPage extends Vue {
  @Getter organization
  @Getter organizationToEdit
  @Getter me
  @Getter permissions

  @Mutation('organization') setOrganization
  @Mutation initOrganization
  @Mutation('breadcrumbId') setBreadcrumbId

  @Action getOrganization
  @Action updateOrganization
  @Action getOrganizationList

  @Prop() id: string;

  nav = 'edit'
  loading = true

  @Watch('id')
  @Watch('organization')
  onNewId() {
    this.init()
  }

  created() {
    this.init()
  }

  get isNewOrganization() {
    return this.$route.path.includes('new')
  }

  get showAdmin() {
    return this.permissions.includes('mutation.updateOrganization.id')
  }

  async init() {
    await this.loadOrganization()
    if (this.$route.path.includes('new')) {
      this.initOrganization()
    } else {
      await this.fetchData()
    }
    this.loading = false
  }

  async loadOrganization() {
    if (this.organization.id != null) { return }

    let id
    if (this.$route.path.includes('new')) {
      id = this.$route.path.split('/').at(-1)
    } else {
      id = this.$route.path.split('/').at(-2)
    }
    // await this.getOrganizationList({ input: { id } })
  }

  get addEditTitle() {
    return this.$route.path.includes('new')
      ? `${this.$t('organizationEdit.create')} ${this.organizationToEdit.type?.toLowerCase()}`
      : `${this.organizationToEdit.name}`
  }

  async fetchData(queryArgs = { page: 1 }) {
    await this.getOrganization({ input: { id: this.id }, queryArgs })
    this.setBreadcrumbId(this.organizationToEdit.id)
  }

  navigate(nav) {
    this.nav = nav
  }

  async onUpsert(model) {
    const input = model.id != null ? model : {
      id: this.id,
      organizations: [model],
    }
    let message
    try {
      await this.updateOrganization(input)
      // TODO: error handling should be in the store
    } catch (e) {
      message = this.nav === 'edit'
        ? `Unable to update ${model.name}, something went wrong.`
        : `Unable to add new ${this.organization.type.toLowerCase()}, something went wrong.`
      this.$message.error(message)
      return
    }
    message = this.nav === 'add'
      ? `New ${this.organization.type.toLowerCase()} added successfully`
      : `${model.name} successfully updated.`
    this.$message.success(message)
    await this.$router.go(-1)
  }
}

