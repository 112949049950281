
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DownloadBar extends Vue {
  @Prop({ default: false })
  redirect: boolean

  @Prop({ default: true })
  hideKebab: boolean

  @Prop({ required: true })
  icon: string

  @Prop({ required: true })
  appName: string

  @Prop()
  appDescription: string

  @Prop()
  link: string

  name = 'DownloadBar'

  onClick() {
    console.log('test')
  }
}
