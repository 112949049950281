




































import { Watch, Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

@Component
export default class App extends Vue {
  @Getter token
  @Getter messages
  @Mutation('token') setToken

  // TODO: this will be of course more elaborate, checking for token, etc.
  get loggedIn() {
    return this.token != null
  }

  get locale() {
    return this.$i18n.locale.toUpperCase()
  }

  get hasMenuBar() {
    return this.$route.meta.hasMenuBar ?? true
  }

  changeLanguage(cc: string) {
    this.$i18n.locale = cc
  }

  logout() {
    this.setToken(null)
    window.localStorage.removeItem('token')
    this.$router.push('/login')
  }

  @Watch('messages')
  displayMessage() {
    if (this.messages.length < 1) { return }
    const message = this.messages.shift()
    if (message.extensions.status != null) {
      if (message.extensions.status === 200) {
        this.$message.success(message.message)
        return
      }
      if (message.extensions.status === 403) {
        if (this.$route.path !== '/') {
          this.$router.push('/')
        }
      }
      this.$message({
        message: `(${message.extensions.status}) - ${message.message}`,
        type: 'error',
      })
      return
    }
    this.$message(message.message)
  }
}
