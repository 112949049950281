import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AddressArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type AddressInputSchema = {
  id?: Maybe<Scalars['String']>;
  streetNameNumber?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AddressSchema = {
  __typename?: 'AddressSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  streetNameNumber?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type BreadcrumbsInputSchema = {
  topOrganizationId: Scalars['String'];
  targetOrganizationId: Scalars['String'];
};

export type BreadcrumbsSchema = {
  __typename?: 'BreadcrumbsSchema';
  id: Scalars['String'];
  type: Scalars['Float'];
  name: Scalars['String'];
};

export type CategoryArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type CategoryInputSchema = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
};

export type CategorySchema = {
  __typename?: 'CategorySchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
};

export type CyberAlarmFilterSchema = {
  /** The range start date. */
  from: Scalars['String'];
  /** The range end date. */
  to: Scalars['String'];
};

export type CyberAlarmHistogramFilterSchema = {
  /** The range start date. */
  from: Scalars['String'];
  /** The range end date. */
  to: Scalars['String'];
  /** The bin size, default is hour. */
  interval?: Maybe<GraylogInterval>;
};

export type CyberAlarmHistogramSchema = {
  __typename?: 'CyberAlarmHistogramSchema';
  interval: GraylogInterval;
  results: Scalars['JSON'];
};

export type CyberAlarmMessagesInputSchema = {
  /** The range start date. */
  from: Scalars['String'];
  /** The range end date. */
  to: Scalars['String'];
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type CyberAlarmMessagesMessageContainerSchema = {
  __typename?: 'CyberAlarmMessagesMessageContainerSchema';
  message: CyberAlarmMessagesMessageSchema;
};

export type CyberAlarmMessagesMessageSchema = {
  __typename?: 'CyberAlarmMessagesMessageSchema';
  timestamp: Scalars['String'];
  rt: Scalars['String'];
  src: Scalars['String'];
  dpt: Scalars['String'];
  shost: Scalars['String'];
  proto: Scalars['String'];
  cefSeverity: Scalars['Int'];
  dst: Scalars['String'];
  spt: Scalars['String'];
  smac: Scalars['String'];
  device: Scalars['String'];
  odid: Scalars['String'];
  syslogrt: Scalars['String'];
  cefName: Scalars['String'];
  threatlevel: Scalars['String'];
  syslogsrc: Scalars['String'];
  dhost: Scalars['String'];
  msg: Scalars['String'];
  user?: Maybe<UserSchema>;
};

export type CyberAlarmMessagesSchema = {
  __typename?: 'CyberAlarmMessagesSchema';
  messages: Array<CyberAlarmMessagesMessageContainerSchema>;
  totalResults: Scalars['Int'];
};

export enum CyberAlarmScore {
  Good = 'Good',
  Ok = 'Ok',
  Bad = 'Bad'
}

export type CyberAlarmSummaryAccountSchema = {
  __typename?: 'CyberAlarmSummaryAccountSchema';
  alias: Scalars['String'];
  bytes: Scalars['Float'];
  user: UserSchema;
};

export type CyberAlarmSummarySchema = {
  __typename?: 'CyberAlarmSummarySchema';
  top: Array<CyberAlarmSummaryTopSchema>;
  accounts: Array<CyberAlarmSummaryAccountSchema>;
  priorities: CyberAlarmSummaryTermsSchema;
  score: CyberAlarmScore;
};

export type CyberAlarmSummaryTermsSchema = {
  __typename?: 'CyberAlarmSummaryTermsSchema';
  terms: Scalars['JSON'];
  missing: Scalars['Int'];
  other: Scalars['Int'];
  total: Scalars['Int'];
};

export type CyberAlarmSummaryTopSchema = {
  __typename?: 'CyberAlarmSummaryTopSchema';
  field: Scalars['String'];
  result: CyberAlarmSummaryTermsSchema;
};

export type FileArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type FileInputSchema = {
  id?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type FileSchema = {
  __typename?: 'FileSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  originalName?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  uploadLocation?: Maybe<Scalars['String']>;
  downloadLocation?: Maybe<Scalars['String']>;
};

export enum GraylogInterval {
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
  Week = 'WEEK',
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE'
}

export type InviteUsersInputSchema = {
  emails: Array<Scalars['String']>;
  cyberAlarm?: Maybe<Scalars['Boolean']>;
  teamId?: Maybe<Scalars['String']>;
};

export type IsChildOrganizationInputSchema = {
  parentId: Scalars['String'];
  childId: Scalars['String'];
};


export type LoginInputSchema = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginSchema = {
  __typename?: 'LoginSchema';
  token: Scalars['String'];
  userId: Scalars['String'];
  user: UserSchema;
};

export type MetricArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type MetricInputSchema = {
  id?: Maybe<Scalars['String']>;
  snapshot?: Maybe<MetricSnapshotInputSchema>;
  value?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type MetricSchema = {
  __typename?: 'MetricSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
  unit: Scalars['String'];
  snapshot: MetricSnapshotSchema;
};

export type MetricSnapshotArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type MetricSnapshotInputSchema = {
  id?: Maybe<Scalars['String']>;
  metric?: Maybe<Array<MetricInputSchema>>;
  server?: Maybe<ServerInputSchema>;
};

export type MetricSnapshotSchema = {
  __typename?: 'MetricSnapshotSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metric: Array<MetricSchema>;
  server: ServerSchema;
};

export type Mutation = {
  __typename?: 'Mutation';
  createRole: RoleSchema;
  updateRole: RoleSchema;
  deleteRole: Scalars['Boolean'];
  createPermissionNode: PermissionNodeSchema;
  updatePermissionNode: PermissionNodeSchema;
  deletePermissionNode: Scalars['Boolean'];
  createOrganization: OrganizationSchema;
  updateOrganization: OrganizationSchema;
  deleteOrganization: Scalars['Boolean'];
  createTeam: TeamSchema;
  updateTeam: TeamSchema;
  deleteTeam: Scalars['Boolean'];
  createUser: UserSchema;
  updateUser: UserSchema;
  deleteUser: Scalars['Boolean'];
  updateLastLogin: Scalars['Boolean'];
  updateLastActivity: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  inviteUsers: Scalars['Boolean'];
  removeRole: Scalars['Boolean'];
  createProduct: ProductSchema;
  updateProduct: ProductSchema;
  deleteProduct: Scalars['Boolean'];
  createAddress: AddressSchema;
  updateAddress: AddressSchema;
  deleteAddress: Scalars['Boolean'];
  microsoftLogin: LoginSchema;
  emailPasswordLogin: LoginSchema;
  b2bExport: FileSchema;
  createFile: FileSchema;
  updateFile: FileSchema;
  deleteFile: Scalars['Boolean'];
  legacyImport: Scalars['Boolean'];
  createServer: ServerSchema;
  updateServer: ServerSchema;
  deleteServer: Scalars['Boolean'];
  createCategory: CategorySchema;
  updateCategory: CategorySchema;
  deleteCategory: Scalars['Boolean'];
  createMetricSnapshot: MetricSnapshotSchema;
  updateMetricSnapshot: MetricSnapshotSchema;
  deleteMetricSnapshot: Scalars['Boolean'];
  createMetric: MetricSchema;
  updateMetric: MetricSchema;
  deleteMetric: Scalars['Boolean'];
};


export type MutationCreateRoleArgs = {
  input: RoleInputSchema;
};


export type MutationUpdateRoleArgs = {
  input: RoleInputSchema;
};


export type MutationDeleteRoleArgs = {
  input: Scalars['String'];
};


export type MutationCreatePermissionNodeArgs = {
  input: PermissionNodeInputSchema;
};


export type MutationUpdatePermissionNodeArgs = {
  input: PermissionNodeInputSchema;
};


export type MutationDeletePermissionNodeArgs = {
  input: Scalars['String'];
};


export type MutationCreateOrganizationArgs = {
  input: OrganizationInputSchema;
};


export type MutationUpdateOrganizationArgs = {
  input: OrganizationInputSchema;
};


export type MutationDeleteOrganizationArgs = {
  input: Scalars['String'];
};


export type MutationCreateTeamArgs = {
  input: TeamInputSchema;
};


export type MutationUpdateTeamArgs = {
  input: TeamInputSchema;
};


export type MutationDeleteTeamArgs = {
  input: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInputSchema;
};


export type MutationUpdateUserArgs = {
  input: UserInputSchema;
};


export type MutationDeleteUserArgs = {
  input: Scalars['String'];
};


export type MutationUpdateLastLoginArgs = {
  legacyUserId: Scalars['String'];
  lastLogin: Scalars['String'];
};


export type MutationUpdateLastActivityArgs = {
  legacyUserId: Scalars['String'];
  lastActivity: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  input: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  input: Scalars['String'];
};


export type MutationInviteUsersArgs = {
  input: InviteUsersInputSchema;
  mailUser?: Maybe<Scalars['Boolean']>;
};


export type MutationRemoveRoleArgs = {
  userId: Scalars['String'];
  roleId: Scalars['String'];
};


export type MutationCreateProductArgs = {
  input: ProductInputSchema;
};


export type MutationUpdateProductArgs = {
  input: ProductInputSchema;
};


export type MutationDeleteProductArgs = {
  input: Scalars['String'];
};


export type MutationCreateAddressArgs = {
  input: AddressInputSchema;
};


export type MutationUpdateAddressArgs = {
  input: AddressInputSchema;
};


export type MutationDeleteAddressArgs = {
  input: Scalars['String'];
};


export type MutationMicrosoftLoginArgs = {
  input: Scalars['String'];
};


export type MutationEmailPasswordLoginArgs = {
  input: LoginInputSchema;
};


export type MutationCreateFileArgs = {
  input: FileInputSchema;
};


export type MutationUpdateFileArgs = {
  input: FileInputSchema;
};


export type MutationDeleteFileArgs = {
  input: Scalars['String'];
};


export type MutationLegacyImportArgs = {
  data: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateServerArgs = {
  input: ServerInputSchema;
};


export type MutationUpdateServerArgs = {
  input: ServerInputSchema;
};


export type MutationDeleteServerArgs = {
  input: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  input: CategoryInputSchema;
};


export type MutationUpdateCategoryArgs = {
  input: CategoryInputSchema;
};


export type MutationDeleteCategoryArgs = {
  input: Scalars['String'];
};


export type MutationCreateMetricSnapshotArgs = {
  input: MetricSnapshotInputSchema;
};


export type MutationUpdateMetricSnapshotArgs = {
  input: MetricSnapshotInputSchema;
};


export type MutationDeleteMetricSnapshotArgs = {
  input: Scalars['String'];
};


export type MutationCreateMetricArgs = {
  input: MetricInputSchema;
};


export type MutationUpdateMetricArgs = {
  input: MetricInputSchema;
};


export type MutationDeleteMetricArgs = {
  input: Scalars['String'];
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrganizationArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type OrganizationInputSchema = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<OrganizationType>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  users?: Maybe<Array<UserInputSchema>>;
  organizations?: Maybe<Array<OrganizationInputSchema>>;
  teams?: Maybe<Array<TeamInputSchema>>;
  products?: Maybe<Array<ProductInputSchema>>;
  address?: Maybe<AddressInputSchema>;
};

export type OrganizationQueryArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type OrganizationSchema = {
  __typename?: 'OrganizationSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  type: OrganizationType;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  users: Array<UserSchema>;
  organizations: Array<OrganizationSchema>;
  teams: Array<TeamSchema>;
  products: Array<ProductSchema>;
  address: AddressSchema;
  parent?: Maybe<OrganizationSchema>;
};


export type OrganizationSchemaUsersArgs = {
  queryArgs?: Maybe<UserQueryArgs>;
};


export type OrganizationSchemaOrganizationsArgs = {
  queryArgs?: Maybe<OrganizationArgs>;
};


export type OrganizationSchemaTeamsArgs = {
  queryArgs?: Maybe<OrganizationArgs>;
};

export enum OrganizationType {
  Goose = 'GOOSE',
  Distributor = 'DISTRIBUTOR',
  Reseller = 'RESELLER',
  Organization = 'ORGANIZATION'
}

export type PermissionNodeArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type PermissionNodeInputSchema = {
  id?: Maybe<Scalars['String']>;
  node?: Maybe<Scalars['String']>;
};

export type PermissionNodeSchema = {
  __typename?: 'PermissionNodeSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  node: Scalars['String'];
};

export type ProductArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type ProductInputSchema = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pricePerMonth?: Maybe<Scalars['Float']>;
  pricePerYear?: Maybe<Scalars['Float']>;
  organization?: Maybe<OrganizationInputSchema>;
};

export type ProductSchema = {
  __typename?: 'ProductSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  pricePerMonth: Scalars['Float'];
  pricePerYear: Scalars['Float'];
  organization: OrganizationSchema;
};

export enum Protocol {
  OpenVpn = 'OpenVPN',
  IpSec = 'IpSec',
  IkEv2 = 'IKEv2',
  L2Tp = 'L2TP',
  Pptp = 'PPTP'
}

export type Query = {
  __typename?: 'Query';
  role: Array<RoleSchema>;
  permissionNode: Array<PermissionNodeSchema>;
  organization: Array<OrganizationSchema>;
  findAdmins: Array<UserSchema>;
  breadcrumbs: Array<BreadcrumbsSchema>;
  isChildOrganization: Scalars['Boolean'];
  team: Array<TeamSchema>;
  user: Array<UserSchema>;
  me: UserSchema;
  legacyToken: Scalars['String'];
  product: Array<ProductSchema>;
  address: Array<AddressSchema>;
  alarmMessages: CyberAlarmMessagesSchema;
  file: Array<FileSchema>;
  server: Array<ServerSchema>;
  category: Array<CategorySchema>;
  metricSnapshot: Array<MetricSnapshotSchema>;
  metric: Array<MetricSchema>;
  serverList: Array<ServerListSchema>;
};


export type QueryRoleArgs = {
  queryArgs?: Maybe<RoleArgs>;
  input?: Maybe<Array<RoleInputSchema>>;
};


export type QueryPermissionNodeArgs = {
  queryArgs?: Maybe<PermissionNodeArgs>;
  input?: Maybe<Array<PermissionNodeInputSchema>>;
};


export type QueryOrganizationArgs = {
  queryArgs?: Maybe<OrganizationQueryArgs>;
  input?: Maybe<Array<OrganizationInputSchema>>;
};


export type QueryFindAdminsArgs = {
  organizationId: Scalars['String'];
};


export type QueryBreadcrumbsArgs = {
  input: BreadcrumbsInputSchema;
};


export type QueryIsChildOrganizationArgs = {
  input: IsChildOrganizationInputSchema;
};


export type QueryTeamArgs = {
  queryArgs?: Maybe<TeamArgs>;
  input?: Maybe<Array<TeamInputSchema>>;
};


export type QueryUserArgs = {
  queryArgs?: Maybe<UserQueryArgs>;
  input?: Maybe<Array<UserInputSchema>>;
};


export type QueryProductArgs = {
  queryArgs?: Maybe<ProductArgs>;
  input?: Maybe<Array<ProductInputSchema>>;
};


export type QueryAddressArgs = {
  queryArgs?: Maybe<AddressArgs>;
  input?: Maybe<Array<AddressInputSchema>>;
};


export type QueryAlarmMessagesArgs = {
  input: CyberAlarmMessagesInputSchema;
};


export type QueryFileArgs = {
  queryArgs?: Maybe<FileArgs>;
  input?: Maybe<Array<FileInputSchema>>;
};


export type QueryServerArgs = {
  queryArgs?: Maybe<ServerArgs>;
  input?: Maybe<Array<ServerInputSchema>>;
};


export type QueryCategoryArgs = {
  queryArgs?: Maybe<CategoryArgs>;
  input?: Maybe<Array<CategoryInputSchema>>;
};


export type QueryMetricSnapshotArgs = {
  queryArgs?: Maybe<MetricSnapshotArgs>;
  input?: Maybe<Array<MetricSnapshotInputSchema>>;
};


export type QueryMetricArgs = {
  queryArgs?: Maybe<MetricArgs>;
  input?: Maybe<Array<MetricInputSchema>>;
};

export type RoleArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type RoleInputSchema = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<RoleInputSchema>>;
  nodes?: Maybe<Array<PermissionNodeInputSchema>>;
  categories?: Maybe<Array<CategoryInputSchema>>;
};

export type RoleSchema = {
  __typename?: 'RoleSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  roles: Array<RoleSchema>;
  nodes: Array<PermissionNodeSchema>;
  categories: Array<CategorySchema>;
};

export type ServerArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type ServerInputSchema = {
  id?: Maybe<Scalars['String']>;
  fullyQualifiedDomainName?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationInputSchema>;
  categories?: Maybe<Array<CategoryInputSchema>>;
  metrics?: Maybe<Array<MetricSnapshotInputSchema>>;
};

export type ServerListLocationSchema = {
  __typename?: 'ServerListLocationSchema';
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type ServerListSchema = {
  __typename?: 'ServerListSchema';
  short_name: Scalars['String'];
  continent: Scalars['String'];
  country_code: Scalars['String'];
  country_flag: Scalars['String'];
  city: Scalars['String'];
  location: ServerListLocationSchema;
  is_p2p: Scalars['Float'];
  is_b2b: Scalars['Float'];
  is_alarms: Scalars['Float'];
  is_alive: Scalars['Float'];
  load_percentage: Scalars['Float'];
  is_streaming: Scalars['Float'];
  is_private: Scalars['Float'];
  distance: Scalars['Float'];
  rounded_distance: Scalars['Float'];
  is_nearest: Scalars['Float'];
  is_nearest_b2b: Scalars['Float'];
  ip: Scalars['String'];
};

export type ServerSchema = {
  __typename?: 'ServerSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  fullyQualifiedDomainName: Scalars['String'];
  ip: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  continent?: Maybe<Scalars['String']>;
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  enabled: Scalars['Boolean'];
  organization?: Maybe<OrganizationSchema>;
  categories?: Maybe<Array<CategorySchema>>;
  metrics: Array<MetricSnapshotSchema>;
};

export type TeamArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
};

export type TeamInputSchema = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<UserInputSchema>>;
  organization?: Maybe<OrganizationInputSchema>;
  cyberAlarm?: Maybe<Scalars['Boolean']>;
  alwaysOn?: Maybe<Scalars['Boolean']>;
  reference?: Maybe<Scalars['String']>;
  allowedProtocol?: Maybe<Array<Protocol>>;
  allowedSsids?: Maybe<Array<Scalars['String']>>;
  whiteListedAlarms?: Maybe<Array<Scalars['String']>>;
  cyberAlarmEmails?: Maybe<Array<Scalars['String']>>;
  cyberAlarmAdminEmails?: Maybe<Array<Scalars['String']>>;
  preventDnsLeak?: Maybe<Scalars['Boolean']>;
  trustedCableNetwork?: Maybe<Scalars['Boolean']>;
};

export type TeamSchema = {
  __typename?: 'TeamSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  cyberAlarm: Scalars['Boolean'];
  alwaysOn: Scalars['Boolean'];
  reference?: Maybe<Scalars['String']>;
  allowedProtocol?: Maybe<Array<Protocol>>;
  allowedSsids?: Maybe<Array<Scalars['String']>>;
  whiteListedAlarms?: Maybe<Array<Scalars['String']>>;
  cyberAlarmEmails?: Maybe<Array<Scalars['String']>>;
  cyberAlarmAdminEmails?: Maybe<Array<Scalars['String']>>;
  preventDnsLeak?: Maybe<Scalars['Boolean']>;
  trustedCableNetwork?: Maybe<Scalars['Boolean']>;
  alarmMessages: CyberAlarmMessagesSchema;
  alarmHistogram: CyberAlarmHistogramSchema;
  alarmSummary: CyberAlarmSummarySchema;
  users: Array<UserSchema>;
  organization: OrganizationSchema;
};


export type TeamSchemaAlarmMessagesArgs = {
  input: CyberAlarmMessagesInputSchema;
};


export type TeamSchemaAlarmHistogramArgs = {
  input: CyberAlarmHistogramFilterSchema;
};


export type TeamSchemaAlarmSummaryArgs = {
  input: CyberAlarmFilterSchema;
};

export type UserInputSchema = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cyberAlarm?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  status?: Maybe<UserStatus>;
  legacyUserId?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationInputSchema>;
  team?: Maybe<TeamInputSchema>;
  reference?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  lastActivity?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<RoleInputSchema>>;
  nodes?: Maybe<Array<PermissionNodeInputSchema>>;
  odId?: Maybe<Scalars['Int']>;
};

export type UserOrderBy = {
  email?: Maybe<Order>;
};

export type UserQueryArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  queryString?: Maybe<Scalars['String']>;
  orderBy?: Maybe<UserOrderBy>;
};

export type UserSchema = {
  __typename?: 'UserSchema';
  id: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  cyberAlarm: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  status: UserStatus;
  legacyUserId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  lastActivity?: Maybe<Scalars['String']>;
  odId?: Maybe<Scalars['Int']>;
  alarmMessages: CyberAlarmMessagesSchema;
  alarmHistogram: CyberAlarmHistogramSchema;
  alarmSummary: CyberAlarmSummarySchema;
  organization: OrganizationSchema;
  team?: Maybe<TeamSchema>;
  nodes?: Maybe<Array<PermissionNodeSchema>>;
  roles?: Maybe<Array<RoleSchema>>;
};


export type UserSchemaAlarmMessagesArgs = {
  input: CyberAlarmMessagesInputSchema;
};


export type UserSchemaAlarmHistogramArgs = {
  input: CyberAlarmHistogramFilterSchema;
};


export type UserSchemaAlarmSummaryArgs = {
  input: CyberAlarmFilterSchema;
};

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type OrganizationFieldsFragment = (
  { __typename?: 'OrganizationSchema' }
  & Pick<OrganizationSchema, 'name' | 'type' | 'id' | 'createdAt' | 'reference'>
  & { users: Array<(
    { __typename?: 'UserSchema' }
    & Pick<UserSchema, 'id' | 'createdAt' | 'email'>
  )> }
);

export type AdminTreeQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminTreeQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & { organizations: Array<(
      { __typename?: 'OrganizationSchema' }
      & { organizations: Array<(
        { __typename?: 'OrganizationSchema' }
        & { organizations: Array<(
          { __typename?: 'OrganizationSchema' }
          & OrganizationFieldsFragment
        )> }
        & OrganizationFieldsFragment
      )> }
      & OrganizationFieldsFragment
    )> }
    & OrganizationFieldsFragment
  )> }
);

export type B2bExportMutationVariables = Exact<{ [key: string]: never; }>;


export type B2bExportMutation = (
  { __typename?: 'Mutation' }
  & { b2bExport: (
    { __typename?: 'FileSchema' }
    & Pick<FileSchema, 'downloadLocation'>
  ) }
);

export type BreadcrumbsQueryVariables = Exact<{
  input: BreadcrumbsInputSchema;
}>;


export type BreadcrumbsQuery = (
  { __typename?: 'Query' }
  & { breadcrumbs: Array<(
    { __typename?: 'BreadcrumbsSchema' }
    & Pick<BreadcrumbsSchema, 'id' | 'name' | 'type'>
  )> }
);

export type AddressFragmentFragment = (
  { __typename?: 'AddressSchema' }
  & Pick<AddressSchema, 'id' | 'streetNameNumber' | 'zipcode' | 'city' | 'country'>
);

export type ProductFragmentFragment = (
  { __typename?: 'ProductSchema' }
  & Pick<ProductSchema, 'id' | 'name' | 'pricePerMonth' | 'pricePerYear'>
);

export type OrganizationFragmentFragment = (
  { __typename?: 'OrganizationSchema' }
  & Pick<OrganizationSchema, 'id' | 'type' | 'name' | 'email' | 'phoneNumber'>
  & { organizations: Array<(
    { __typename?: 'OrganizationSchema' }
    & Pick<OrganizationSchema, 'id' | 'type' | 'name' | 'createdAt'>
  )>, users: Array<(
    { __typename?: 'UserSchema' }
    & UserTableLineFragmentFragment
  )>, address: (
    { __typename?: 'AddressSchema' }
    & AddressFragmentFragment
  ), products: Array<(
    { __typename?: 'ProductSchema' }
    & ProductFragmentFragment
  )> }
);

export type OrganizationQueryVariables = Exact<{
  input: OrganizationInputSchema;
}>;


export type OrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & Pick<OrganizationSchema, 'id' | 'type' | 'name' | 'email' | 'phoneNumber'>
    & { teams: Array<(
      { __typename?: 'TeamSchema' }
      & Pick<TeamSchema, 'id' | 'reference' | 'name'>
    )>, address: (
      { __typename?: 'AddressSchema' }
      & AddressFragmentFragment
    ), products: Array<(
      { __typename?: 'ProductSchema' }
      & ProductFragmentFragment
    )> }
  )> }
);

export type SearchUsersQueryVariables = Exact<{
  organizationId: Scalars['String'];
  query: Scalars['String'];
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & { users: Array<(
      { __typename?: 'UserSchema' }
      & Pick<UserSchema, 'email'>
      & { roles?: Maybe<Array<(
        { __typename?: 'RoleSchema' }
        & Pick<RoleSchema, 'name'>
      )>> }
    )> }
  )> }
);

export type GetAdminsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type GetAdminsQuery = (
  { __typename?: 'Query' }
  & { findAdmins: Array<(
    { __typename?: 'UserSchema' }
    & Pick<UserSchema, 'id' | 'email'>
  )> }
);

export type OrganizationUserQueryVariables = Exact<{
  input: OrganizationInputSchema;
}>;


export type OrganizationUserQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & Pick<OrganizationSchema, 'id' | 'type' | 'name' | 'email' | 'phoneNumber'>
    & { teams: Array<(
      { __typename?: 'TeamSchema' }
      & Pick<TeamSchema, 'id' | 'reference' | 'name'>
    )>, address: (
      { __typename?: 'AddressSchema' }
      & AddressFragmentFragment
    ), products: Array<(
      { __typename?: 'ProductSchema' }
      & ProductFragmentFragment
    )> }
  )> }
);

export type OrganizationListQueryVariables = Exact<{
  input: OrganizationInputSchema;
  queryArgs: OrganizationArgs;
}>;


export type OrganizationListQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & Pick<OrganizationSchema, 'id' | 'type' | 'name'>
    & { organizations: Array<(
      { __typename?: 'OrganizationSchema' }
      & Pick<OrganizationSchema, 'id' | 'reference' | 'type' | 'name' | 'createdAt'>
    )> }
  )> }
);

export type UpdateOrganizationMutationVariables = Exact<{
  input: OrganizationInputSchema;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization: (
    { __typename?: 'OrganizationSchema' }
    & OrganizationFragmentFragment
  ) }
);

export type DeleteOrganizationMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrganization'>
);

export type IsChildOrganizationQueryVariables = Exact<{
  input: IsChildOrganizationInputSchema;
}>;


export type IsChildOrganizationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isChildOrganization'>
);

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & { role: Array<(
    { __typename?: 'RoleSchema' }
    & Pick<RoleSchema, 'id' | 'name'>
  )> }
);

export type GetServerListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServerListQuery = (
  { __typename?: 'Query' }
  & { serverList: Array<(
    { __typename?: 'ServerListSchema' }
    & Pick<ServerListSchema, 'short_name' | 'country_code' | 'continent' | 'city'>
  )> }
);

export type TeamsQueryVariables = Exact<{
  input: TeamInputSchema;
}>;


export type TeamsQuery = (
  { __typename?: 'Query' }
  & { team: Array<(
    { __typename?: 'TeamSchema' }
    & Pick<TeamSchema, 'id' | 'name' | 'createdAt' | 'cyberAlarm' | 'alwaysOn'>
  )> }
);

export type TeamQueryVariables = Exact<{
  input: TeamInputSchema;
}>;


export type TeamQuery = (
  { __typename?: 'Query' }
  & { team: Array<(
    { __typename?: 'TeamSchema' }
    & Pick<TeamSchema, 'id' | 'name' | 'createdAt'>
    & { users: Array<(
      { __typename?: 'UserSchema' }
      & Pick<UserSchema, 'id'>
    )> }
  )> }
);

export type GetTeamsFromOrganizationQueryVariables = Exact<{
  input: OrganizationInputSchema;
  queryArgs: OrganizationArgs;
}>;


export type GetTeamsFromOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & Pick<OrganizationSchema, 'id'>
    & { teams: Array<(
      { __typename?: 'TeamSchema' }
      & Pick<TeamSchema, 'id' | 'name' | 'createdAt' | 'cyberAlarm' | 'alwaysOn' | 'reference' | 'preventDnsLeak' | 'allowedProtocol' | 'allowedSsids' | 'trustedCableNetwork' | 'whiteListedAlarms' | 'cyberAlarmEmails' | 'cyberAlarmAdminEmails'>
      & { users: Array<(
        { __typename?: 'UserSchema' }
        & Pick<UserSchema, 'id'>
      )> }
    )> }
  )> }
);

export type DeleteTeamMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteTeamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeam'>
);

export type CreateTeamMutationVariables = Exact<{
  input: TeamInputSchema;
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam: (
    { __typename?: 'TeamSchema' }
    & Pick<TeamSchema, 'id' | 'name' | 'createdAt'>
  ) }
);

export type UpdateTeamMutationVariables = Exact<{
  input: TeamInputSchema;
}>;


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam: (
    { __typename?: 'TeamSchema' }
    & Pick<TeamSchema, 'id' | 'name' | 'createdAt'>
    & { users: Array<(
      { __typename?: 'UserSchema' }
      & Pick<UserSchema, 'id'>
    )>, organization: (
      { __typename?: 'OrganizationSchema' }
      & Pick<OrganizationSchema, 'id'>
    ) }
  ) }
);

export type UserTableLineFragmentFragment = (
  { __typename?: 'UserSchema' }
  & Pick<UserSchema, 'id' | 'email' | 'status' | 'cyberAlarm' | 'createdAt' | 'reference' | 'odId' | 'lastLogin' | 'lastActivity'>
  & { team?: Maybe<(
    { __typename?: 'TeamSchema' }
    & Pick<TeamSchema, 'id' | 'name'>
  )> }
);

export type LegacyTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type LegacyTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'legacyToken'>
);

export type RemoveRoleMutationVariables = Exact<{
  userId: Scalars['String'];
  roleId: Scalars['String'];
}>;


export type RemoveRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRole'>
);

export type ForgotPasswordMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type CreateUserMutationVariables = Exact<{
  input: UserInputSchema;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserSchema' }
    & Pick<UserSchema, 'id'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInputSchema;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserSchema' }
    & Pick<UserSchema, 'id'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type LoginMutationVariables = Exact<{
  input: LoginInputSchema;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { emailPasswordLogin: (
    { __typename?: 'LoginSchema' }
    & Pick<LoginSchema, 'token'>
    & { user: (
      { __typename?: 'UserSchema' }
      & Pick<UserSchema, 'id'>
    ) }
  ) }
);

export type ExchangeMicrosoftTokenMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type ExchangeMicrosoftTokenMutation = (
  { __typename?: 'Mutation' }
  & { microsoftLogin: (
    { __typename?: 'LoginSchema' }
    & Pick<LoginSchema, 'token'>
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserSchema' }
    & { organization: (
      { __typename?: 'OrganizationSchema' }
      & Pick<OrganizationSchema, 'type' | 'id' | 'name'>
    ), roles?: Maybe<Array<(
      { __typename?: 'RoleSchema' }
      & Pick<RoleSchema, 'name'>
      & { nodes: Array<(
        { __typename?: 'PermissionNodeSchema' }
        & Pick<PermissionNodeSchema, 'node'>
      )> }
    )>> }
  ) }
);

export type SearchUserQueryVariables = Exact<{
  organizationId: Scalars['String'];
  query: Scalars['String'];
}>;


export type SearchUserQuery = (
  { __typename?: 'Query' }
  & { user: Array<(
    { __typename?: 'UserSchema' }
    & Pick<UserSchema, 'id' | 'email'>
  )> }
);

export type UserQueryVariables = Exact<{
  input: UserInputSchema;
  query?: Maybe<UserQueryArgs>;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: Array<(
    { __typename?: 'UserSchema' }
    & UserTableLineFragmentFragment
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { user: Array<(
    { __typename?: 'UserSchema' }
    & Pick<UserSchema, 'id' | 'createdAt' | 'firstName' | 'lastName' | 'email' | 'cyberAlarm' | 'status'>
  )> }
);

export type GetUsersFromOrganizationQueryVariables = Exact<{
  input: OrganizationInputSchema;
  queryArgs: UserQueryArgs;
}>;


export type GetUsersFromOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & Pick<OrganizationSchema, 'id'>
    & { users: Array<(
      { __typename?: 'UserSchema' }
      & UserTableLineFragmentFragment
    )> }
  )> }
);

export type GetUsersFromOrganizationUsersQueryVariables = Exact<{
  input: OrganizationInputSchema;
}>;


export type GetUsersFromOrganizationUsersQuery = (
  { __typename?: 'Query' }
  & { organization: Array<(
    { __typename?: 'OrganizationSchema' }
    & Pick<OrganizationSchema, 'id'>
    & { teams: Array<(
      { __typename?: 'TeamSchema' }
      & Pick<TeamSchema, 'name'>
      & { users: Array<(
        { __typename?: 'UserSchema' }
        & Pick<UserSchema, 'id' | 'email' | 'status' | 'cyberAlarm' | 'createdAt' | 'reference'>
      )> }
    )> }
  )> }
);

export type InviteUsersMutationVariables = Exact<{
  input: InviteUsersInputSchema;
}>;


export type InviteUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteUsers'>
);

export const OrganizationFieldsFragmentDoc = gql`
    fragment organizationFields on OrganizationSchema {
  name
  type
  id
  createdAt
  reference
  users(queryArgs: {size: 1000}) {
    id
    createdAt
    email
  }
}
    `;
export const UserTableLineFragmentFragmentDoc = gql`
    fragment UserTableLineFragment on UserSchema {
  id
  email
  status
  cyberAlarm
  createdAt
  reference
  odId
  lastLogin
  lastActivity
  team {
    id
    name
  }
}
    `;
export const AddressFragmentFragmentDoc = gql`
    fragment AddressFragment on AddressSchema {
  id
  streetNameNumber
  zipcode
  city
  country
}
    `;
export const ProductFragmentFragmentDoc = gql`
    fragment ProductFragment on ProductSchema {
  id
  name
  pricePerMonth
  pricePerYear
}
    `;
export const OrganizationFragmentFragmentDoc = gql`
    fragment OrganizationFragment on OrganizationSchema {
  id
  type
  name
  email
  phoneNumber
  organizations {
    id
    type
    name
    createdAt
  }
  users {
    ...UserTableLineFragment
  }
  address {
    ...AddressFragment
  }
  products {
    ...ProductFragment
  }
}
    ${UserTableLineFragmentFragmentDoc}
${AddressFragmentFragmentDoc}
${ProductFragmentFragmentDoc}`;
export const AdminTreeDocument = gql`
    query adminTree {
  organization {
    ...organizationFields
    organizations(queryArgs: {size: 1000}) {
      ...organizationFields
      organizations(queryArgs: {size: 1000}) {
        ...organizationFields
        organizations(queryArgs: {size: 1000}) {
          ...organizationFields
        }
      }
    }
  }
}
    ${OrganizationFieldsFragmentDoc}`;
export const B2bExportDocument = gql`
    mutation b2bExport {
  b2bExport {
    downloadLocation
  }
}
    `;
export const BreadcrumbsDocument = gql`
    query breadcrumbs($input: BreadcrumbsInputSchema!) {
  breadcrumbs(input: $input) {
    id
    name
    type
  }
}
    `;
export const OrganizationDocument = gql`
    query organization($input: OrganizationInputSchema!) {
  organization(input: [$input], queryArgs: {size: 25}) {
    id
    type
    name
    email
    phoneNumber
    teams {
      id
      reference
      name
    }
    address {
      ...AddressFragment
    }
    products {
      ...ProductFragment
    }
  }
}
    ${AddressFragmentFragmentDoc}
${ProductFragmentFragmentDoc}`;
export const SearchUsersDocument = gql`
    query searchUsers($organizationId: String!, $query: String!) {
  organization(input: {id: $organizationId}, queryArgs: {queryString: $query}) {
    users {
      email
      roles {
        name
      }
    }
  }
}
    `;
export const GetAdminsDocument = gql`
    query getAdmins($organizationId: String!) {
  findAdmins(organizationId: $organizationId) {
    id
    email
  }
}
    `;
export const OrganizationUserDocument = gql`
    query organizationUser($input: OrganizationInputSchema!) {
  organization(input: [$input]) {
    id
    type
    name
    email
    phoneNumber
    teams {
      id
      reference
      name
    }
    address {
      ...AddressFragment
    }
    products {
      ...ProductFragment
    }
  }
}
    ${AddressFragmentFragmentDoc}
${ProductFragmentFragmentDoc}`;
export const OrganizationListDocument = gql`
    query organizationList($input: OrganizationInputSchema!, $queryArgs: OrganizationArgs!) {
  organization(input: [$input]) {
    id
    type
    name
    organizations(queryArgs: $queryArgs) {
      id
      reference
      type
      name
      createdAt
    }
  }
}
    `;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: OrganizationInputSchema!) {
  updateOrganization(input: $input) {
    ...OrganizationFragment
  }
}
    ${OrganizationFragmentFragmentDoc}`;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($input: String!) {
  deleteOrganization(input: $input)
}
    `;
export const IsChildOrganizationDocument = gql`
    query isChildOrganization($input: IsChildOrganizationInputSchema!) {
  isChildOrganization(input: $input)
}
    `;
export const GetRolesDocument = gql`
    query getRoles {
  role {
    id
    name
  }
}
    `;
export const GetServerListDocument = gql`
    query getServerList {
  serverList {
    short_name
    country_code
    continent
    city
  }
}
    `;
export const TeamsDocument = gql`
    query teams($input: TeamInputSchema!) {
  team(input: [$input]) {
    id
    name
    createdAt
    cyberAlarm
    alwaysOn
  }
}
    `;
export const TeamDocument = gql`
    query team($input: TeamInputSchema!) {
  team(input: [$input]) {
    id
    name
    createdAt
    users {
      id
    }
  }
}
    `;
export const GetTeamsFromOrganizationDocument = gql`
    query getTeamsFromOrganization($input: OrganizationInputSchema!, $queryArgs: OrganizationArgs!) {
  organization(input: [$input]) {
    id
    teams(queryArgs: $queryArgs) {
      id
      name
      createdAt
      cyberAlarm
      alwaysOn
      reference
      preventDnsLeak
      allowedProtocol
      allowedSsids
      trustedCableNetwork
      whiteListedAlarms
      cyberAlarmEmails
      cyberAlarmAdminEmails
      users {
        id
      }
    }
  }
}
    `;
export const DeleteTeamDocument = gql`
    mutation deleteTeam($input: String!) {
  deleteTeam(input: $input)
}
    `;
export const CreateTeamDocument = gql`
    mutation createTeam($input: TeamInputSchema!) {
  createTeam(input: $input) {
    id
    name
    createdAt
  }
}
    `;
export const UpdateTeamDocument = gql`
    mutation updateTeam($input: TeamInputSchema!) {
  updateTeam(input: $input) {
    id
    name
    createdAt
    users {
      id
    }
    organization {
      id
    }
  }
}
    `;
export const LegacyTokenDocument = gql`
    query legacyToken {
  legacyToken
}
    `;
export const RemoveRoleDocument = gql`
    mutation removeRole($userId: String!, $roleId: String!) {
  removeRole(userId: $userId, roleId: $roleId)
}
    `;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: String!) {
  forgotPassword(input: $input)
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: String!) {
  resetPassword(input: $input)
}
    `;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInputSchema!) {
  createUser(input: $input) {
    id
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInputSchema!) {
  updateUser(input: $input) {
    id
  }
}
    `;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: String!) {
  deleteUser(input: $input)
}
    `;
export const LoginDocument = gql`
    mutation login($input: LoginInputSchema!) {
  emailPasswordLogin(input: $input) {
    token
    user {
      id
    }
  }
}
    `;
export const ExchangeMicrosoftTokenDocument = gql`
    mutation exchangeMicrosoftToken($input: String!) {
  microsoftLogin(input: $input) {
    token
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    organization {
      type
      id
      name
    }
    roles {
      name
      nodes {
        node
      }
    }
  }
}
    `;
export const SearchUserDocument = gql`
    query searchUser($organizationId: String!, $query: String!) {
  user(
    input: {organization: {id: $organizationId}}
    queryArgs: {queryString: $query}
  ) {
    id
    email
  }
}
    `;
export const UserDocument = gql`
    query user($input: UserInputSchema!, $query: UserQueryArgs) {
  user(input: [$input], queryArgs: $query) {
    ...UserTableLineFragment
  }
}
    ${UserTableLineFragmentFragmentDoc}`;
export const UsersDocument = gql`
    query users {
  user {
    id
    createdAt
    firstName
    lastName
    email
    cyberAlarm
    status
  }
}
    `;
export const GetUsersFromOrganizationDocument = gql`
    query getUsersFromOrganization($input: OrganizationInputSchema!, $queryArgs: UserQueryArgs!) {
  organization(input: [$input]) {
    id
    users(queryArgs: $queryArgs) {
      ...UserTableLineFragment
    }
  }
}
    ${UserTableLineFragmentFragmentDoc}`;
export const GetUsersFromOrganizationUsersDocument = gql`
    query getUsersFromOrganizationUsers($input: OrganizationInputSchema!) {
  organization(input: [$input]) {
    id
    teams {
      name
      users {
        id
        email
        status
        cyberAlarm
        createdAt
        reference
      }
    }
  }
}
    `;
export const InviteUsersDocument = gql`
    mutation inviteUsers($input: InviteUsersInputSchema!) {
  inviteUsers(input: $input)
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    adminTree(variables?: AdminTreeQueryVariables): Promise<AdminTreeQuery> {
      return withWrapper(() => client.request<AdminTreeQuery>(print(AdminTreeDocument), variables));
    },
    b2bExport(variables?: B2bExportMutationVariables): Promise<B2bExportMutation> {
      return withWrapper(() => client.request<B2bExportMutation>(print(B2bExportDocument), variables));
    },
    breadcrumbs(variables: BreadcrumbsQueryVariables): Promise<BreadcrumbsQuery> {
      return withWrapper(() => client.request<BreadcrumbsQuery>(print(BreadcrumbsDocument), variables));
    },
    organization(variables: OrganizationQueryVariables): Promise<OrganizationQuery> {
      return withWrapper(() => client.request<OrganizationQuery>(print(OrganizationDocument), variables));
    },
    searchUsers(variables: SearchUsersQueryVariables): Promise<SearchUsersQuery> {
      return withWrapper(() => client.request<SearchUsersQuery>(print(SearchUsersDocument), variables));
    },
    getAdmins(variables: GetAdminsQueryVariables): Promise<GetAdminsQuery> {
      return withWrapper(() => client.request<GetAdminsQuery>(print(GetAdminsDocument), variables));
    },
    organizationUser(variables: OrganizationUserQueryVariables): Promise<OrganizationUserQuery> {
      return withWrapper(() => client.request<OrganizationUserQuery>(print(OrganizationUserDocument), variables));
    },
    organizationList(variables: OrganizationListQueryVariables): Promise<OrganizationListQuery> {
      return withWrapper(() => client.request<OrganizationListQuery>(print(OrganizationListDocument), variables));
    },
    updateOrganization(variables: UpdateOrganizationMutationVariables): Promise<UpdateOrganizationMutation> {
      return withWrapper(() => client.request<UpdateOrganizationMutation>(print(UpdateOrganizationDocument), variables));
    },
    deleteOrganization(variables: DeleteOrganizationMutationVariables): Promise<DeleteOrganizationMutation> {
      return withWrapper(() => client.request<DeleteOrganizationMutation>(print(DeleteOrganizationDocument), variables));
    },
    isChildOrganization(variables: IsChildOrganizationQueryVariables): Promise<IsChildOrganizationQuery> {
      return withWrapper(() => client.request<IsChildOrganizationQuery>(print(IsChildOrganizationDocument), variables));
    },
    getRoles(variables?: GetRolesQueryVariables): Promise<GetRolesQuery> {
      return withWrapper(() => client.request<GetRolesQuery>(print(GetRolesDocument), variables));
    },
    getServerList(variables?: GetServerListQueryVariables): Promise<GetServerListQuery> {
      return withWrapper(() => client.request<GetServerListQuery>(print(GetServerListDocument), variables));
    },
    teams(variables: TeamsQueryVariables): Promise<TeamsQuery> {
      return withWrapper(() => client.request<TeamsQuery>(print(TeamsDocument), variables));
    },
    team(variables: TeamQueryVariables): Promise<TeamQuery> {
      return withWrapper(() => client.request<TeamQuery>(print(TeamDocument), variables));
    },
    getTeamsFromOrganization(variables: GetTeamsFromOrganizationQueryVariables): Promise<GetTeamsFromOrganizationQuery> {
      return withWrapper(() => client.request<GetTeamsFromOrganizationQuery>(print(GetTeamsFromOrganizationDocument), variables));
    },
    deleteTeam(variables: DeleteTeamMutationVariables): Promise<DeleteTeamMutation> {
      return withWrapper(() => client.request<DeleteTeamMutation>(print(DeleteTeamDocument), variables));
    },
    createTeam(variables: CreateTeamMutationVariables): Promise<CreateTeamMutation> {
      return withWrapper(() => client.request<CreateTeamMutation>(print(CreateTeamDocument), variables));
    },
    updateTeam(variables: UpdateTeamMutationVariables): Promise<UpdateTeamMutation> {
      return withWrapper(() => client.request<UpdateTeamMutation>(print(UpdateTeamDocument), variables));
    },
    legacyToken(variables?: LegacyTokenQueryVariables): Promise<LegacyTokenQuery> {
      return withWrapper(() => client.request<LegacyTokenQuery>(print(LegacyTokenDocument), variables));
    },
    removeRole(variables: RemoveRoleMutationVariables): Promise<RemoveRoleMutation> {
      return withWrapper(() => client.request<RemoveRoleMutation>(print(RemoveRoleDocument), variables));
    },
    forgotPassword(variables: ForgotPasswordMutationVariables): Promise<ForgotPasswordMutation> {
      return withWrapper(() => client.request<ForgotPasswordMutation>(print(ForgotPasswordDocument), variables));
    },
    resetPassword(variables: ResetPasswordMutationVariables): Promise<ResetPasswordMutation> {
      return withWrapper(() => client.request<ResetPasswordMutation>(print(ResetPasswordDocument), variables));
    },
    createUser(variables: CreateUserMutationVariables): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(print(CreateUserDocument), variables));
    },
    updateUser(variables: UpdateUserMutationVariables): Promise<UpdateUserMutation> {
      return withWrapper(() => client.request<UpdateUserMutation>(print(UpdateUserDocument), variables));
    },
    deleteUser(variables: DeleteUserMutationVariables): Promise<DeleteUserMutation> {
      return withWrapper(() => client.request<DeleteUserMutation>(print(DeleteUserDocument), variables));
    },
    login(variables: LoginMutationVariables): Promise<LoginMutation> {
      return withWrapper(() => client.request<LoginMutation>(print(LoginDocument), variables));
    },
    exchangeMicrosoftToken(variables: ExchangeMicrosoftTokenMutationVariables): Promise<ExchangeMicrosoftTokenMutation> {
      return withWrapper(() => client.request<ExchangeMicrosoftTokenMutation>(print(ExchangeMicrosoftTokenDocument), variables));
    },
    me(variables?: MeQueryVariables): Promise<MeQuery> {
      return withWrapper(() => client.request<MeQuery>(print(MeDocument), variables));
    },
    searchUser(variables: SearchUserQueryVariables): Promise<SearchUserQuery> {
      return withWrapper(() => client.request<SearchUserQuery>(print(SearchUserDocument), variables));
    },
    user(variables: UserQueryVariables): Promise<UserQuery> {
      return withWrapper(() => client.request<UserQuery>(print(UserDocument), variables));
    },
    users(variables?: UsersQueryVariables): Promise<UsersQuery> {
      return withWrapper(() => client.request<UsersQuery>(print(UsersDocument), variables));
    },
    getUsersFromOrganization(variables: GetUsersFromOrganizationQueryVariables): Promise<GetUsersFromOrganizationQuery> {
      return withWrapper(() => client.request<GetUsersFromOrganizationQuery>(print(GetUsersFromOrganizationDocument), variables));
    },
    getUsersFromOrganizationUsers(variables: GetUsersFromOrganizationUsersQueryVariables): Promise<GetUsersFromOrganizationUsersQuery> {
      return withWrapper(() => client.request<GetUsersFromOrganizationUsersQuery>(print(GetUsersFromOrganizationUsersDocument), variables));
    },
    inviteUsers(variables: InviteUsersMutationVariables): Promise<InviteUsersMutation> {
      return withWrapper(() => client.request<InviteUsersMutation>(print(InviteUsersDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;