















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { TeamSchema } from '@/generated/sdk'
import { fromUnixTime } from 'date-fns'
import { formatDate } from '@/utils'
import { Action, Getter, Mutation } from 'vuex-class'
import TeamEditDialog from '@/components/organizations/TeamEditDialog.vue'
@Component({
  components: { TeamEditDialog },
})
export default class TeamsTable extends Vue {
  query = ''
  teamDialogVisible = false

  @Getter('team') model
  @Getter teams
  @Getter organizationToEdit

  @Action getTeams
  @Action deleteTeam
  @Action createTeam
  @Action updateTeam
  @Action getOrganization
  @Action getUsers

  @Mutation initTeam
  @Mutation('team') setTeam

  page = 1

  @Watch('page')
  onNewPage() {
    this.fetchData()
  }

  created() {
    this.fetchData()
  }

  @Watch('organizationToEdit')
  async fetchData() {
    // TODO: dirty fix, this will be resolved when the tabs are separately rendered
    if (this.organizationToEdit.id == null) {
      return
    }
    this.getTeams({
      input: { id: this.organizationToEdit.id },
      queryArgs: {
        page: this.page,
        ...(this.query !== '' && { queryString: this.query }),
      },
    })
  }

  formatDate(date: number): string {
    return formatDate(fromUnixTime(date / 1000), 'dd MM yyyy')
  }

  addTeam(): void {
    this.initTeam(this.organizationToEdit.id)
    this.teamDialogVisible = true
  }

  editTeam(teamId: string): void {
    const team = this.teams.find(t => t.id === teamId)
    this.teamDialogVisible = true
    this.setTeam(team)
    this.$emit('edit')
  }

  async removeTeam(id: string): Promise<void> {
    try {
      await this.$confirm('Are you sure you want to remove this team?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
      const success = await this.deleteTeam(id)
      if (success) {
        setTimeout(() => {
          this.fetchData()
        }, 1000)
      }
    } catch (error) {}
  }

  rowClick(input: TeamSchema) {
    this.$emit('row-click', input.id)
  }

  async submit(input: TeamSchema): Promise<void> {
    if (input.id == null) {
      await this.createTeam(input)
    } else {
      await this.updateTeam(input)
      await this.fetchData()
      // TODO: this should not be neccessary but right now in the users lsit the teams displayed do not come from the same state
      // in general these tabs should be decoupled in a bit and only the active one should be rendered
      await this.getUsers({ input: { id: this.organizationToEdit.id }, queryArgs: { page: 1 } })
    }

    await this.fetchData()
    this.teamDialogVisible = false
  }

  onSearch() {
    this.page = 1
    this.fetchData()
  }
}

