export const translations = {
  gb: {
    downloads: {
      apps: 'Apps',
      router: 'Router',
      manual: 'Manual settings',
      title: 'Downloads',
      yourToken: 'Your token:',
      manualSettingsTab: {
        title: 'GOOSE VPN manual settings',
        description: 'It is possible and sometimes necessary to set up GOOSE VPN manually, for example if you do this on your NAS or Linux device. Using OpenVPN? Even then manual installation may be necessary.</br>View all manuals for manual settings here.',
        serverFilesTitle: 'Server files',
        nasInstructionsTitle: 'NAS instructions',
        linuxInstructionsTitle: 'Linux instructions',
        serverFiles1: 'Comodo RSA Trust certificate',
        serverFiles2: 'Comodo RSA Domain certificate',
        serverFiles3: 'GOOSE VPN OpenVPN CA certificate',
        nas1: 'Synology OS v5.x + L2TP',
        nas1link: 'https://goosevpn.zendesk.com/hc/en-us/articles/5081054921490-Install-GOOSE-VPN-with-L2TP-on-NAS-Synology-OS',
        nas3: 'Synology OS v5.x + Open VPN',
        nas3link: 'https://goosevpn.zendesk.com/hc/en-us/articles/5081142986130-Install-GOOSE-VPN-with-OpenVPN-on-NAS-Synology-OS',
      },
      routerTab: {
        title: 'GOOSE VPN manual settings',
        description: 'It is possible to install GOOSE VPN on your router. </br>View all manuals for manual settings here.',
        listTitle: 'Router',
        router1: 'Asus RT-AC66U',
        router1link: 'https://goosevpn.zendesk.com/hc/en-us/articles/4954652931858-Install-GOOSE-VPN-with-OpenVPN-for-Asus-RT-AC66U-routers',
        router2: 'Asus RT-(N16/N18U/AC68U/AC87U/AC3200)',
        router2link: 'https://goosevpn.zendesk.com/hc/en-us/articles/4954822412690-Install-GOOSE-VPN-with-OpenVPN-on-Asus-RT-N16-RT-N18U-RT-N66U-RT-AC68U-RT-AC87U-RT-AC3200-routers',
        router3: 'TomatoUSB firmware',
        router3link: 'https://goosevpn.zendesk.com/hc/en-us/articles/4955414096914-Install-GOOSE-VPN-on-TomatoUSB-Router',
        router4: 'Netgear (L2TP mode)',
        router4link: 'https://goosevpn.zendesk.com/hc/en-us/articles/4957286302354-Install-GOOSE-VPN-on-Netgear-Router-L2TP-mode-',
        router5: 'D-Link series with L2TP/PPtP support',
        router5link: 'https://goosevpn.zendesk.com/hc/en-us/articles/4957631629074-Install-GOOSE-VPN-on-D-Link-series-router',
        router6: 'Setup GOOSE VPN via OpenVPN for DD-WRT',
        router6link: 'https://goosevpn.zendesk.com/hc/en-us/articles/5080124583570-Install-GOOSE-VPN-with-OpenVPN-for-DD-WRT-Routers',
      },
      appsTab: {
        qrTitle: 'Scan the QR-code',
        qrDescription: 'Use your mobile device to scan and download the app',
        stepsTitle: 'Safely online in 3 steps',
        desktopTitle: 'Desktops and laptops',
        mobileTitle: 'Mobile apps',
        questionsTitle: 'Need help or have any questions?',
        stepOne: 'Download the app',
        stepTwo: 'Login with your account',
        stepThree: 'Start the secured connection in the app',
        downloadMacos: 'Download GOOSE VPN for macOS',
        download: 'Download app',
        windows: 'Windows',
        windowsDescription: 'For Windows 7 and newer',
        windowsVersion: '5.0.0.8 MSI',
        macOS: 'Mac',
        macOSDescription: 'For macOS 10.12 and newer',
        macOSVersion: 'V5.1.3',
        iOS: 'iOS',
        iOSDescription: 'Apple',
        linux: 'Linux',
        android: 'Android',
        androidDescription: 'Android',
        faqLink: 'Check our FAQ →',
        manualsLink: 'Download manuals →',
        contactSupport: 'Or contact our support via ',
        windowsMenu: {
          main: 'Windows 7/8/10/11',
          oldMain: 'Windows 7/8/10 (old version)',
          legacy: 'Windows XP/Vista',
          instructions: 'View instructions OpenVPN Windows',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/en-us/articles/4867296791058-Install-GOOSE-VPN-with-OpenVPN-Connect-on-Windows',
        },
        linuxMenu: {
          instructions: 'View instructions Linux',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/en-us/articles/4853635537554-Install-GOOSE-VPN-with-Open-VPN-on-Linux-via-Linux-GUI',
        },
        macMenu: {
          directDownload: 'Direct download',
          appStore: 'App Store',
          appStoreLink: 'https://apps.apple.com/us/app/goose-vpn/id1373335581?mt=12',
          instructions: 'View instructions OpenVPN macOS',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/en-us/articles/4865891810962-Install-GOOSE-VPN-with-OpenVPN-Connect-on-macOS',

        },
        iosMenu: {
          appStore: 'App Store',
          appStoreLink: 'https://apps.apple.com/us/app/goose-vpn/id1122473789?l=nl&ls=1',
          instructions: 'View instructions OpenVPN iOS',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/en-us/articles/4862771321746-Install-GOOSE-VPN-with-OpenVPN-Connect-on-iOS',
        },
        androidMenu: {
          appStore: 'App Store',
          apk: 'APK download',
          instructions: 'View instructions OpenVPN Android',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/en-us/articles/4853350382738-Install-GOOSE-VPN-with-OpenVPN-Connect-on-Android',
        },
      },
    },
    home: {
      welcome: 'Welcome!',
      welcomeMessage: 'GOOSE Business is the only VPN provider with malware detection. GOOSE Business scans traffic in the background and warns you as soon as you are at risk online. Manage all your employees here and enjoy a safe workplace from anywhere.',
    },
    navBar: {
      home: 'Home',
      Distributors: 'Distributors',
      Resellers: 'Resellers',
      Organizations: 'Organizations',
      admin: 'Admin (temp)',
      cyberAlarm: 'Cyber alarm',
      downloads: 'Downloads',
      support: 'Support/FAQ',
      logout: 'Log out',
    },
    admin: {
      title: 'Admin area',
      excelExport: 'Excel export',
      filterPlaceholder: 'Filter keyword',

    },
    passwordReset: {
      message: 'Password has been reset reset, a new password has been emailed',
      toLogin: 'To login',
    },
    login: {
      email: 'Email',
      password: 'Password',
      rememberMe: 'Remember me',
      forgotPassword: 'Forgot Password?',
      login: 'Log In',
      title: 'Login',
    },
    general: {
      search: 'Search...',
      cancel: 'Cancel',
      save: 'Save',
      warning: 'Warning',
      confirm: 'Confirm',
      edit: 'Edit',
      deactivate: 'Deactivate',
      remove: 'Remove',
      distributor: 'Distributor',
      reseller: 'Reseller',
      organization: 'Organization',
      add: 'Add',
      select: 'Select',
    },
    adminTable: {
      title: 'Manage your Admins',
      removeAdmin: 'Remove admin',
    },
    organizationTable: {
      name: 'NAME',
      created: 'CREATED',
    },
    organizationEdit: {
      create: 'Create new',
      teamsTab: 'Teams',
      usersTab: 'Users',
      adminTab: 'Admins',
      billingTab: 'Billing',
      edit: {
        company: 'Company Name',
        address: 'Billing Address',
        zipcode: 'Zipcode',
        city: 'City',
        country: 'Country',
        countryPlaceholder: 'Select',
        phone: 'Phone',
        invoiceEmail: 'Invoice Email',
      },
      teams: {
        add: 'Add team',
        table: {
          name: 'NAME',
          created: 'CREATED',
        },
        edit: {
          name: 'Team name',
          policies: 'Policies',
          alwaysOn: 'Always on',
          cyberAlarm: 'Cyber Alarm only',
          preventDnsLeak: 'Prevent DNS leak',
          trustedCableNetwork: 'Trusted cable network',
          protocols: 'Allowed protocols',
          whitelist: 'White listed alarms',
          networks: 'Trusted networks',
          cyberAlarmEmails: 'Emails for Cyber Alarm notifications',
          cyberAlarmAdminEmails: 'Emails for Cyber Alarm notifications',
        },
      },
      billing: {
        title: 'Your prices',
        product: 'PRODUCT',
        perMonth: 'PRICE PER MONTH',
        perYear: 'PRICE PER YEAR',
      },
      users: {
        add: 'Add user',
        import: 'Import users',
        csvError: 'There was a problem parsing this file, make sure this is a CSV',
        removeUser: 'Are you sure you want to remove this user?',
        table: {
          odId: 'ODID',
          status: 'STATUS',
          email: 'EMAIL',
          team: 'TEAM',
          teamSelectPlaceholder: 'Select',
          cyberAlarm: 'CYBER ALARM',
          created: 'CREATED',
          lastActivity: 'LAST ACTIVITY',
          deactivate: 'Deactivate',
          activate: 'Activate',
          remove: 'Remove',
          noLogin: 'No login yet',
        },
        invite: {
          title: 'Add new user(s)',
          subtitle: 'New member? Invite by email address(es) below:',
          email: 'Email address(es)',
          suggestion: 'Start typing to add an email',
          emailPlaceholder: 'Invite users',
          cyberAlarm: 'Cyber alarm',
          team: 'Team',
          invite: 'Invite',
        },
      },
    },
  },
  nl: {
    downloads: {
      apps: 'Apps',
      router: 'Router',
      manual: 'Handmatig instellen',
      title: 'Downloads',
      yourToken: 'Jouw token:',
      manualSettingsTab: {
        title: 'GOOSE VPN handmatige instellingen',
        description: 'Het is mogelijk en soms nodig om GOOSE VPN handmatig in te stellen, bijvoorbeeld als je dit op je NAS of Linux apparaat wilt instellen. Open VPN gebruiken? Ook dan kan handmatige installatie nodig zijn. </br>Bekijk hier alle handleidingen voor de handmatige instellingen.',
        serverFilesTitle: 'Server documenten',
        nasInstructionsTitle: 'NAS instructies',
        linuxInstructionsTitle: 'Linux instructies',
        serverFiles1: 'Comodo RSA Trust certificaat',
        serverFiles2: 'Comodo RSA Domain certificaat',
        serverFiles3: 'GOOSE VPN OpenVPN CA certificaat',
        nas1: 'Synology OS v5.x + L2TP',
        nas1link: 'https://goosevpn.zendesk.com/hc/nl/articles/5081054921490-GOOSE-VPN-installeren-met-L2TP-op-NAS-Synology-OS',
        nas3: 'Synology OS v5.x + Open VPN',
        nas3link: 'https://goosevpn.zendesk.com/hc/nl/articles/5081142986130-GOOSE-VPN-installeren-met-OpenVPN-op-NAS-Synology-OS',
      },
      routerTab: {
        title: 'GOOSE VPN Router instellingen',
        description: 'Het is mogelijk om GOOSE VPN op je router te installeren. </br>Bekijk hier alle handleidingen voor de handmatige instellingen.',
        listTitle: 'Router',
        router1: 'Asus RT-AC66U',
        router1link: 'https://goosevpn.zendesk.com/hc/nl/articles/4954652931858-GOOSE-VPN-installeren-met-OpenVPN-voor-Asus-RT-AC66U-router',
        router2: 'Asus RT-(N16/N18U/AC68U/AC87U/AC3200)',
        router2link: 'https://goosevpn.zendesk.com/hc/nl/articles/4954822412690-GOOSE-VPN-installeren-met-OpenVPN-voor-Asus-RT-N16-RT-N18U-RT-N66U-RT-AC68U-RT-AC87U-RT-AC3200-routers',
        router3: 'TomatoUSB firmware',
        router3link: 'https://goosevpn.zendesk.com/hc/nl/articles/4955414096914-GOOSE-VPN-installeren-op-TomatoUSB-Router',
        router4: 'Netgear (L2TP mode)',
        router4link: 'https://goosevpn.zendesk.com/hc/nl/articles/4957286302354-GOOSE-VPN-installeren-op-Netgear-Router-L2TP-mode-',
        router5: 'D-Link series met L2TP/PPtP support',
        router5link: 'https://goosevpn.zendesk.com/hc/nl/articles/4957631629074-GOOSE-VPN-installeren-op-D-Link-series-router',
        router6: 'Setup GOOSE VPN via OpenVPN for DD-WRT',
        router6link: 'https://goosevpn.zendesk.com/hc/nl/articles/5080124583570-GOOSE-VPN-installeren-met-OpenVPN-voor-DD-WRT-Routers',
      },
      appsTab: {
        qrTitle: 'Scan de QR-code',
        qrDescription: 'Gebruik je mobiele apparaat om de app te scannen en de app te downloaden',
        stepsTitle: 'Veilig online in 3 stappen',
        desktopTitle: 'Desktops en laptops',
        mobileTitle: 'Mobiele apps',
        questionsTitle: 'Hulp nodig of vragen?',
        stepOne: 'Download de app',
        stepTwo: 'Login met jouw account',
        stepThree: 'Start de beveiligde verbinding in de app',
        downloadMacos: 'Download GOOSE VPN voor macOS',
        download: 'Download app',
        windows: 'Windows',
        windowsDescription: 'Voor Windows 7 en nieuwer',
        windowsVersion: '5.0.0.8 MSI',
        macOS: 'Mac',
        macOSDescription: 'Voor macOS 10.12 en nieuwer',
        macOSVersion: 'V5.1.3',
        iOS: 'iOS',
        iOSDescription: 'Apple',
        linux: 'Linux',
        android: 'Android',
        androidDescription: 'Android',
        faqLink: 'Bekijk onze FAQ →',
        manualsLink: 'Download manuals →',
        contactSupport: 'Of contact ons via support ',
        windowsMenu: {
          main: 'Windows 7/8/10/11',
          oldMain: 'Windows 7/8/10 (oude versie)',
          legacy: 'Windows XP/Vista',
          instructions: 'Bekijk instructies OpenVPN Windows',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/nl/articles/4867296791058-GOOSE-VPN-installeren-met-OpenVPN-Connect-op-Windows',
        },
        linuxMenu: {
          instructions: 'Bekijk instructies Linux',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/nl/articles/4853635537554-GOOSE-VPN-installeren-met-OpenVPN-op-Linux-via-de-Linux-GUI',
        },
        macMenu: {
          directDownload: 'Direct downloaden',
          appStore: 'App Store',
          appStoreLink: 'https://apps.apple.com/nl/app/goose-vpn/id1373335581?mt=12',
          instructions: 'Bekijk instructies OpenVPN macOS',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/nl/articles/4865891810962-GOOSE-VPN-installeren-met-OpenVPN-Connect-op-macOS',
        },
        iosMenu: {
          appStore: 'App Store',
          appStoreLink: 'https://apps.apple.com/nl/app/goose-vpn/id1122473789?l=nl&ls=1',
          instructions: 'Bekijk instructies OpenVPN iOS',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/nl/articles/4862771321746-GOOSE-VPN-installeren-met-OpenVPN-Connect-op-iOS',
        },
        androidMenu: {
          appStore: 'App Store',
          apk: 'APK downloaden',
          instructions: 'Bekijk instructies OpenVPN Android',
          instructionsLink: 'https://goosevpn.zendesk.com/hc/nl/articles/4853350382738-GOOSE-VPN-installeren-met-OpenVPN-Connect-op-Android',
        },
      },
    },
    home: {
      welcome: 'Welkom!',
      welcomeMessage: 'GOOSE Zakelijk is de enige VPN-aanbieder met malwaredetectie. GOOSE Zakelijk scant het verkeer op de achtergrond en waarschuwt u zodra u online gevaar loopt. Beheer hier uw account en geniet overal van een veilige werkplek.',
    },
    navBar: {
      home: 'Home',
      Distributors: 'Distribiteurs',
      Resellers: 'Resellers',
      Organizations: 'Organisaties',
      admin: 'Admin (temp)',
      cyberAlarm: 'Cyber alarm',
      downloads: 'Downloads',
      support: 'Support/FAQ',
      logout: 'Uitloggen',
    },
    admin: {
      title: 'Admin area',
      excelExport: 'Excel export',
      filterPlaceholder: 'Filter keyword',

    },
    passwordReset: {
      message: 'Uw wachtwoord is gereset, wij hebben hier een e-mail van verzonden.',
      toLogin: 'Inloggen',
    },
    login: {
      email: 'Email',
      password: 'Wachtwoord',
      remember_me: 'Herriner mij',
      forgotPassword: 'Wachtwoord vergeten?',
      login: 'Inloggen',
    },
    general: {
      search: 'Zoeken...',
      cancel: 'Annuleer',
      save: 'Bewaar',
      warning: 'Waarschuwing',
      confirm: 'Bevestig',
      edit: 'Wijzig',
      deactivate: 'Deactief',
      remove: 'Verwijder',
      distributor: 'Distribiteur',
      reseller: 'Reseller',
      organization: 'Organisatie',
      add: 'Toevoegen',
      select: 'Selecteren',
    },
    adminTable: {
      title: 'Beheer Admins',
      removeAdmin: 'Verwijder admin',
    },
    organizationTable: {
      name: 'NAAM',
      created: 'SINDS',
    },
    organizationEdit: {
      create: 'Maak nieuwe aan',
      teamsTab: 'Teams',
      usersTab: 'Users',
      adminTab: 'Admins',
      billingTab: 'Facturatie',
      edit: {
        company: 'Bedrijfsnaam',
        address: 'Factuuradres',
        zipcode: 'Postcode',
        city: 'Stad',
        country: 'Land',
        countryPlaceholder: 'Selecteer',
        phone: 'Telefoon',
        invoiceEmail: 'Factuur Email',
      },
      teams: {
        add: 'Voeg team toe',
        table: {
          name: 'NAAM',
          created: 'SINDS',
        },
        edit: {
          name: 'Team naam',
          policies: 'Policies',
          alwaysOn: 'Altijd aan',
          cyberAlarm: 'Alleen Cyber Alarm',
          preventDnsLeak: 'Voorkom DNS lek',
          protocols: 'Toegstaane protocolen',
          whitelist: 'White listed alarmen',
          networks: 'Vertrouwde netwerken',
        },
      },
      billing: {
        title: 'Uw prijs',
        product: 'PRODUCT',
        perMonth: 'PRIJS PER MAAND',
        perYear: 'PRIJS PER JAAR',
      },
      users: {
        add: 'Toevoegen user',
        import: 'Importeren users',
        csvError: 'Er heeft een probleem zich voorgedaan, zorg ervoor dat het een CSV file is.',
        removeUser: 'Weet u zeker dat u deze user wilt verwijderen?',
        table: {
          odId: 'ODID',
          status: 'STATUS',
          email: 'EMAIL',
          team: 'TEAM',
          teamSelectPlaceholder: 'Selecteren',
          cyberAlarm: 'CYBER ALARM',
          created: 'SINDS',
          deactivate: 'Deactief',
          activate: 'Actief',
          remove: 'Verwijder',
        },
        invite: {
          title: 'Voeg nieuwe user(s) toe',
          subtitle: 'Nieuwe user? Nodig uw collega uit door het e-mail adres hieronder toe te voegen :',
          email: 'Email addres(sen)',
          suggestion: 'Start met typen om een user toe te voegen',
          emailPlaceholder: 'Uitnodigen users',
          cyberAlarm: 'Cyber alarm',
          team: 'Team',
          invite: 'Verstuur uitnodiging',
        },
      },
    },
  },

}
