import { sdk } from '@/api'

export default {
  state: () => {
    return {
      roles: [],
    }
  },

  getters: {
    roles(state) {
      return state.roles
    },
  },

  mutations: {
    roles(state, value) {
      state.roles = value
    },
  },

  actions: {
    async getRoles({ commit }) {
      const result = await sdk().getRoles()
      commit('roles', result.role)
    },
  },
}
