import { BreadcrumbsSchema, BreadcrumbsInputSchema } from '@/generated/sdk'
import { sdk } from '@/api'

export default {
  state: () => {
    return {
      breadcrumbs: [] as BreadcrumbsSchema[],
    }
  },

  getters: {
    breadcrumbs(state) {
      return state.breadcrumbs
    },
  },

  mutations: {
    breadcrumbs(state, value) {
      state.breadcrumbs = value
    },
  },

  actions: {
    async fetchBreadcrumbs({ commit }, input: BreadcrumbsInputSchema) {
      const { breadcrumbs } = await sdk().breadcrumbs({ input })
      commit('breadcrumbs', breadcrumbs)
    },
  },
}
