<template>
  <div>
    {{ $t('passwordReset.message') }}<br>
    <a href="/login">{{ $t('passwordReset.toLogin') }}</a>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
}
</script>

<style>
</style>
