














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class Breadcrumb extends Vue {
  @Getter currentUserOrganization
  @Getter breadcrumbs
  @Getter breadcrumbId

  @Action fetchOrganization
  @Action fetchBreadcrumbs

  @Watch('organization')
  onNewId() {
    this.init()
  }

  created() {
    this.init()
  }

  @Watch('breadcrumbId')
  async init() {
    if (this.breadcrumbId == null) {
      return
    }
    const id = this.breadcrumbId
    await this.fetchBreadcrumbs({
      targetOrganizationId: id,
      topOrganizationId: this.currentUserOrganization.id,
    })
  }

  breadcrumbNav(item, index) {
    this.$router.push(`/organizations/${item.id}`)
  }
}

