

































import { Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Component from 'vue-class-component'

@Component
export default class ServerFilesDropdown extends Vue {
  name = 'ServerFilesDropdown'

  @Getter
  serverList

  showFiles = false

  created() {
    this.$store.dispatch('fetchServerList')
  }

  toggleShowFiles() {
    console.log(this.showFiles)
    this.showFiles = !this.showFiles
  }
}
