






















































































































import ElementUI from 'element-ui'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { UserSchema, UserStatus, InviteUsersInputSchema } from '@/generated/sdk'
import { fromUnixTime, parseJSON } from 'date-fns'
import { formatDate } from '@/utils'
import { Action, Getter } from 'vuex-class'
import InviteUsersDialog from '@/components/organizations/InviteUsersDialog.vue'
import MenuKebab from '@/assets/menu-kebab.svg'
import Papa from 'papaparse'

@Component({
  components: { InviteUsersDialog, MenuKebab },
})
export default class UsersTable extends Vue {
  @Prop()
  data: UserSchema[]

  @Getter users
  @Getter teams
  @Getter organizationToEdit

  @Action updateTeam
  @Action deleteUser
  @Action updateUser
  @Action getUsers
  @Action getTeams
  @Action inviteUsers

  page = 1

  query = ''
  openDialog = false

  userStatusColor = {
    Active: '#E7F5F4',
    Pending: '#FFF5E5',
    Inactive: '#FAE8EC',
  }

  sortBy = null

  @Watch('page')
  onNewPage() {
    this.fetchData()
  }

  created() {
    this.fetchData()
  }

  formatLastLoginDate(date) {
    return date != null ? formatDate(date, 'dd MM yyyy') : this.$t('organizationEdit.users.table.noLogin')
  }

  formatDate(date) {
    return formatDate(fromUnixTime(date / 1000), 'dd MM yyyy')
  }

  showErrorFileUpload() {
    ElementUI.Message.error({ message: this.$t('organizationEdit.users.csvError') as string })
  }

  runCommand(a) {
    this[a.fn](a.user)
  }

  onChange(file) {
    Papa.parse(file, {
      delimiter: ',',
      header: true,
      erros: () => {
        this.showErrorFileUpload()
      },
      complete: (result, file) => {
        if (result.errors.length > 0) {
          this.showErrorFileUpload()
          return
        }
        this.inviteUploadedUsers(result.data)
      },
    })
    return false
  }

  inviteUploadedUsers(input: { email: string, cyberAlarm: string, team: string }[]) {
    for (const item of input) {
      if (item.email == null || item.team == null) { continue }
      const team = this.teams.find(t => t.name === item.team)

      if (team == null) { continue }
      this.inviteUsers({
        emails: [item.email],
        cyberAlarm: item.cyberAlarm.toLowerCase() === 'true',
        teamId: team.id,
      } as InviteUsersInputSchema)
    }
  }

  get teamOptions() {
    return this.users
      .filter((u, i, a) => u.team != null && a.findIndex(e => e.team?.id === u.team.id) === i)
      .map(u => ({ id: u.team.id, name: u.team.name }))
      .concat(this.teams.filter(t => !this.users.find(u => u.team?.id === t.id)))
  }

  @Watch('sortBy')
  @Watch('organizationToEdit')
  async fetchData(): Promise<void> {
    // TODO: dirty fix, this will be resolved when the tabs are separately rendered
    if (this.organizationToEdit.id == null) {
      return
    }
    await this.getUsers({
      input: { id: this.organizationToEdit.id },
      queryArgs: {
        page: this.page,
        ...(this.query !== '' && { queryString: this.query }),
        ...(this.sortBy !== null && this.sortBy),
      },
    })
  }

  async findTeams(query) {
    await this.getTeams({ input: { id: this.organizationToEdit.id }, queryArgs: { queryString: query } })
  }

  async removeUser(user) {
    const id = user.id
    try {
      await this.$confirm(this.$t('organizationEdit.users.removeUser') as string, this.$t('general.warning') as string, {
        confirmButtonText: this.$t('general.confirm') as string,
        cancelButtonText: this.$t('general.cancel') as string,
        type: 'warning',
      })
      const success = await this.deleteUser(id)
      if (success) {
        setTimeout(() => {
          this.fetchData()
        }, 500)
      }
    } catch (error) {}
  }

  async toggleActive(user) {
    const status = user.status === 'Active' ? UserStatus.Inactive : UserStatus.Active
    await this.updateUser({
      id: user.id,
      status,
    })
    await this.fetchData()
  }

  addUser() {
    this.openDialog = true
  }

  async toggleCyberAlarm(user) {
    await this.updateUser({
      id: user.id,
      cyberAlarm: user.cyberAlarm,
    })
    await this.fetchData()
  }

  async setTeamForUser(user, team) {
    await this.updateTeam({
      id: team.id,
      users: [
        { id: user.id },
      ],
    })
    await this.fetchData()
  }

  onSearch() {
    this.page = 1
    this.fetchData()
  }

  onSort(args: any): void {
    if (args.prop === 'email' && args.order != null) {
      this.sortBy = {
        orderBy: {
          email: args.order === 'ascending' ? 'ASC' : 'DESC',
        },
      }
    } else {
      this.sortBy = null
    }
  }
}

