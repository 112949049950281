



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Schema from 'async-validator'
import { debounce } from 'lodash'

@Component
export default class EmailSelector extends Vue {
  @Prop()
  value: string[]

  @Prop({ default: 'Add new email' })
  placeholder: string

  currentEmail = ''
  isTyping = false

  debounced = debounce((resolve, reject) => { this.validate().then(resolve).catch(reject) }, 100)

  asyncDebounced = () => new Promise((resolve, reject) => {
    this.debounced(resolve, reject)
  })

  @Watch('value', { deep: true })
  onValueChange() {
    if (this.value == null || this.value.length === 0) {
      this.reset()
    }
  }

  get emails() {
    return this.isTyping ? this.value.slice(0, -1) : this.value
  }

  async onChange(text) {
    this.currentEmail = text
    const valid = await this.asyncDebounced()
    if (!valid) {
      return
    }
    this.isTyping = true
    let emails = this.value ?? []
    emails = emails.concat(this.currentEmail)
    this.$emit('input', emails)
  }

  async onAdd() {
    const valid = await this.asyncDebounced()
    if (!valid) {
      return false
    }
    let emails = this.value ?? []
    if (this.isTyping) {
      emails = emails.slice(0, -1)
    }
    this.$emit('input', emails.concat([this.currentEmail]))
    this.reset()
    return true
  }

  onDelete(index) {
    const emails = this.value
    emails.splice(index, 1)
    this.$emit('input', emails)
  }

  async checkBeforeSubmit() {
    if (this.currentEmail !== '' && !this.isTyping) {
      return await this.onAdd()
    }
    return true
  }

  reset() {
    this.currentEmail = ''
    this.isTyping = false
  }

  async validate(): Promise<boolean> {
    const validator = new Schema({ email: { type: 'email' } })
    try {
      await validator.validate({ email: this.currentEmail })
    } catch {
      this.$message('Invalid mail address')
      return false
    }
    return true
  }
}

