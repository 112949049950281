import {
  AddressSchema,
  OrganizationSchema,
  OrganizationType,
  ProductSchema,
} from '@/generated/sdk'
import { sdk } from '@/api'
import { IsChildOrganizationInputSchema } from '../../../backend/src/user/organization.schema'

export const ADMIN_ROLE = 'Admin'

function cleanOrganization(organization: OrganizationSchema) {
  delete organization.createdAt
  for (const user of organization.users ?? []) {
    delete user.lastLogin
  }
  if (organization.organizations == null) {
    return
  }
  for (const child of organization.organizations) {
    cleanOrganization(child)
  }
}

export const childMap: {[P in OrganizationType]: OrganizationType} = {
  [OrganizationType.Goose]: OrganizationType.Distributor,
  [OrganizationType.Distributor]: OrganizationType.Reseller,
  [OrganizationType.Reseller]: OrganizationType.Organization,
  [OrganizationType.Organization]: null,
}

export default {
  state: () => {
    return {
      currentUserOrganization: null,
      organizationToEdit: {} as OrganizationSchema,
      organization: {} as OrganizationSchema,
      breadcrumbId: null,
      isChildOrganization: false,
    }
  },

  getters: {
    currentUserOrganization(state) {
      return state.currentUserOrganization
    },
    organizationToEdit(state) {
      return state.organizationToEdit
    },
    organization(state) {
      return state.organization
    },
    breadcrumbId(state) {
      return state.breadcrumbId
    },
    isChildOrganization(state) {
      return state.isChildOrganization
    },
  },

  mutations: {
    currentUserOrganization(state, value) {
      state.currentUserOrganization = value
      state.organizationToEdit.products ??= []
      state.organizationToEdit.address ??= {}
      state.organizationToEdit.organizations ??= []
    },
    organizationToEdit(state, value) {
      state.organizationToEdit = value
      state.organizationToEdit.products ??= []
      state.organizationToEdit.address ??= {}
      state.organizationToEdit.organizations ??= []
    },
    organization(state, value) {
      state.organization = value
      state.organizationToEdit.products ??= []
      state.organizationToEdit.address ??= {}
      state.organizationToEdit.organizations ??= []
    },
    initOrganization(state) {
      state.organizationToEdit = {
        type: childMap[state.organization.type],
        address: {} as AddressSchema,
        products: [] as ProductSchema[],
        organizations: [] as OrganizationSchema[],
      } as OrganizationSchema
    },
    breadcrumbId(state, value) {
      state.breadcrumbId = value
    },
    isChildOrganization(state, value) {
      state.isChildOrganization = value
    },
  },

  actions: {
    async getOrganization({ commit, dispatch, getters }, variables) {
      if (getters.userRoles.includes(ADMIN_ROLE)) {
        const result = await dispatch('fetchOrganization', variables)
        commit('organizationToEdit', result)
      } else {
        const result = await dispatch('fetchOrganizationUser', variables)
        commit('organizationToEdit', result)
      }
    },

    async getOrganizationList({ commit }, { input, queryArgs = { page: 1 } }) {
      const variables = {
        input,
        queryArgs,
      }
      const { organization } = await sdk().organizationList(variables)
      commit('organization', organization[0])
    },

    async fetchOrganization({ commit }, input) {
      const { organization } = await sdk().organization(input)
      if (organization[0] == null) {
        throw new Error('No organization found for input' + JSON.stringify(input))
      }
      return organization[0]
    },

    async fetchOrganizationUser({ commit }, input) {
      const { organization } = await sdk().organizationUser(input)
      if (organization[0] == null) {
        throw new Error('No organization found for input' + JSON.stringify(input))
      }
      return organization[0]
    },

    async updateOrganization({ commit }, input) {
      cleanOrganization(input)
      const { updateOrganization } = await sdk().updateOrganization({ input })
      commit('organizationToEdit', updateOrganization)
    },

    async deleteOrganizationFromOrganization({ dispatch, state }, childId) {
      if (!state.organization.organizations.find(o => o.id === childId)) {
        return
      }
      await dispatch('deleteOrganization', childId)
      await dispatch('getOrganizationList', { input: { id: state.organization.id } })
    },

    async deleteOrganization(_, id: string) {
      await sdk().deleteOrganization({ input: id })
    },

    async isChildOrganization({ commit }, input: IsChildOrganizationInputSchema) {
      const { isChildOrganization } = await sdk().isChildOrganization({ input })
      commit('isChildOrganization', isChildOrganization)
    },
  },
}
