import { sdk } from '@/api'

export default {
  state: () => {
    return {
      serverList: [],
    }
  },
  getters: {
    serverList(state) {
      return state.serverList
    },
  },
  mutations: {
    serverList(state, value) {
      state.serverList = value
    },
  },
  actions: {
    async fetchServerList({ commit, dispatch }) {
      try {
        const list = (await sdk().getServerList())?.serverList
        commit('serverList', list)
      } catch {}
    },
  },
}
