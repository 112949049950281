
























































import addressCountryList from '@/data/countries'
import { Component } from 'vue-property-decorator'
import Validator from '@/validator'
import { OrganizationInputSchema } from '@/generated/sdk'
import { Rules } from 'async-validator'
import { Getter, Mutation } from 'vuex-class'

@Component
export default class OrganizationEdit extends Validator<OrganizationInputSchema> {
  @Getter('organizationToEdit') declare model

  addressCountryList = addressCountryList

  rules: Rules = {
    name: { required: true, message: 'Please provide a name.' },
  }

  async submit() {
    if (await this._submit()) {
      this.$emit('upsert', this.model)
    }
  }
}

