
















































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { capitalName } from '@/utils'
import { childMap } from '@/store/organizations'

@Component
export default class NavBar extends Vue {
  @Getter currentUserOrganization

  get route() {
    return this.$route.path
  }

  get OrganizationRoute() {
    return `/organizations/${this.currentUserOrganization.id}`
  }

  get AdminTempRoute() {
    return '/admin-temp'
  }

  get OrganizationName() {
    return capitalName(childMap[this.currentUserOrganization.type].toLowerCase()) + 's'
  }

  toCompany() {
    this.$router.push(`/organizations/${this.currentUserOrganization.id}/details`)
  }

  redirectTo(url) {
    window.location.href = url
  }
}

