




























































































































































import { Component, Vue } from 'vue-property-decorator'
import DownloadBar from '@/components/DownloadBar.vue'

@Component({
  components: { DownloadBar },
})
export default class AppDownloads extends Vue {
  download(url) {
    window.location.href = url
  }
}
