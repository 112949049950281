

























































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { InviteUsersInputSchema } from '@/generated/sdk'
import { Rules } from 'async-validator'
import Validator from '@/validator'

@Component
export default class InviteUsersDialog extends Validator<InviteUsersInputSchema> {
  @Prop()
  value: boolean

  @Getter organizationToEdit
  @Getter teams
  @Action getUsers
  @Action inviteUsers
  @Action getTeams
  @Mutation errorMessage

  model: InviteUsersInputSchema = {
    emails: [],
    cyberAlarm: true,
  }

  rules: Rules = {
    teamId: { required: true, message: 'Please provide a team.' },
    emails: { required: true, message: 'Please provide at least one email address.' },
  }

  loading = false

  $refs: {
    emailSelector: any;
  }

  @Watch('value')
  initModel() {
    this.model = {
      emails: [],
      cyberAlarm: true,
      teamId: this.teams[0]?.id || null,
    }
  }

  created() {
    // TODO: dirty fix, this will be resolved when the tabs are separately rendered
    if (this.organizationToEdit.id != null) {
      this.getTeams({ input: { id: this.organizationToEdit.id } })
    }
  }

  async findTeams(query) {
    await this.getTeams({
      input: { id: this.organizationToEdit.id },
      ...(query !== '' && { queryArgs: { queryString: query } }),
    })
  }

  async onSubmit() {
    const preCheck = await this.$refs.emailSelector.checkBeforeSubmit()
    if (!preCheck) {
      return
    }
    if (!await this._submit()) {
      return
    }
    this.loading = true
    const success = await this.inviteUsers(this.model)
    await this.getUsers({ input: { id: this.organizationToEdit.id } })
    if (success) {
      this.$emit('input', false)
    }
    this.loading = false
  }
}

