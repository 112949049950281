<template>
  <div>
    Password Reset
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PasswordReset extends Vue {
}
</script>

<style>
</style>
