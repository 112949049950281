import { sdk } from '@/api'
import { UserSchema } from '@/generated/sdk'
import { InviteUsersInputSchema } from '../../../backend/src/user/user.schema'

import { ADMIN_ROLE } from './organizations'

export default {
  state: () => {
    return {
      token: localStorage.getItem('token'),
      users: [] as UserSchema[],
      me: null as UserSchema,
      queriedUsers: [] as UserSchema[],
      admins: [] as UserSchema[],
      legacyToken: null,
    }
  },

  getters: {
    token(state) {
      return state.token
    },
    users(state) {
      return state.users
    },
    me(state) {
      return state.me
    },
    permissions(state) {
      return state.me.roles.reduce((p, r) => p.concat(r.nodes.map(n => n.node)), [])
    },
    userRoles(state) {
      return state.me.roles.map(r => r.name)
    },
    queriedUsers(state) {
      return state.queriedUsers
    },
    admins(state) {
      return state.admins
    },
    legacyToken(state) {
      return state.legacyToken
    },
  },

  mutations: {
    token(state, value) {
      state.token = value
      if (value == null) {
        localStorage.removeItem('token')
      } else {
        localStorage.setItem('token', value)
      }
    },
    users(state, value) {
      state.users = value
    },
    me(state, value) {
      state.me = value
    },
    queriedUsers(state, value) {
      state.queriedUsers = value
    },
    admins(state, value) {
      state.admins = value
    },
    legacyToken(state, value) {
      state.legacyToken = value
    },
  },

  actions: {
    async loadLegacyToken({ state, commit }) {
      if (state.legacyToken != null) { return }
      const legacyToken = await sdk().legacyToken()
      commit('legacyToken', legacyToken.legacyToken)
    },

    async getAdmins({ commit }, organizationId) {
      const result = await sdk().getAdmins(organizationId)
      commit('admins', result.findAdmins)
    },

    async searchUsers({ commit }, input) {
      const result = await sdk().searchUser(input)
      commit('queriedUsers', result.user)
    },

    async exchangeMicrosoftToken({ commit, dispatch }, token) {
      const { microsoftLogin } = await sdk().exchangeMicrosoftToken({ input: token })
      commit('token', microsoftLogin.token)
      await dispatch('me')
    },

    async login({ commit, dispatch }, { email, password }) {
      const { emailPasswordLogin } = await sdk().login({ input: { email, password } })
      commit('token', emailPasswordLogin.token)
      await dispatch('me')
    },

    async me({ commit }) {
      const me = (await sdk().me())?.me
      if (me == null) {
        commit('token', undefined)
        return new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            location.reload()
            resolve()
          }
          , 500)
        })
      }
      const currentOrganization = {
        type: me.organization.type,
        id: me.organization.id,
        name: me.organization.name,
      }
      commit('currentUserOrganization', currentOrganization)
      commit('me', me)
    },

    async getUsers({ commit, getters }, { input, queryArgs = { page: 1 } }) {
      const variables = {
        input,
        queryArgs,
      }
      if (getters.userRoles.includes(ADMIN_ROLE)) {
        const { organization } = await sdk().getUsersFromOrganization(variables)
        commit('users', organization[0].users)
      } else {
        const { organization } = await sdk().getUsersFromOrganizationUsers({ input })
        commit('users', organization[0].teams[0]?.users ?? [])
      }
    },

    async forgotPassword(_, email): Promise<boolean> {
      try {
        const { forgotPassword } = await sdk().forgotPassword({ input: email })
        return forgotPassword
      } catch {
        return false
      }
    },

    async resetPassword(_, hash): Promise<boolean> {
      try {
        const { resetPassword } = await sdk().resetPassword({ input: hash })
        return resetPassword
      } catch {
        return false
      }
    },

    async updateUser(_, input): Promise<void> {
      const user = { ...input }
      delete user.team
      await sdk().updateUser({ input: user })
    },

    async deleteUser({ dispatch }, id: string) {
      return (await sdk().deleteUser({ input: id })).deleteUser
    },

    async removeRole(_, input) {
      await sdk().removeRole(input)
    },

    async inviteUsers({ commit }, input: InviteUsersInputSchema) {
      try {
        const { inviteUsers } = await sdk().inviteUsers({ input })
        if (inviteUsers) {
          commit('successMessage', 'Invite successfully sent.')
        }
        return inviteUsers
      } catch (e) {
        commit('errorMessage', e)
        return false
      }
    },
  },
}
