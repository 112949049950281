










































import { Component, Vue } from 'vue-property-decorator'
import Validator from '@/validator'
import type { UserInputSchema } from '@/generated/sdk'
import { Rules } from 'async-validator'
import { Action, Getter } from 'vuex-class'

@Component
export default class LoginForm extends Validator<UserInputSchema> {
  model = {
    email: localStorage.getItem('email'),
  } as UserInputSchema

  rules: Rules = {
    email: { type: 'email', required: true, message: 'Please provide email.' },
    password: { required: true, message: 'Please provide password.' },
  }

  @Getter currentUserOrganization
  @Action login
  @Action forgotPassword

  remember = localStorage.getItem('email') != null

  get microsoftLoginUrl(): string {
    const appUrl = encodeURIComponent('https://egg.goosevpn.com') //
    return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=143b4961-5755-461a-8dd4-4ffbd917a8da&scope=openid%20email&response_type=code%20id_token&redirect_uri=${appUrl}%2Fmicrosoft-login%2F&nonce=93213&code_challenge=8l-5ou6cOC2YvNpMl6Er54vSrR2zcUvnZVOio-MYQBo&code_challenge_method=S256`
  }

  async handleForgotPassword(): Promise<void> {
    if (this.error.email != null || this.model.email == null) {
      this.$message({ type: 'error', message: 'Invalid email' })
      return
    }
    await this.forgotPassword(this.model.email)
    this.$message('Password recovery mail send')
  }

  async sendLogin(): Promise<void> {
    if (this.remember) {
      localStorage.setItem('email', this.model.email)
    } else {
      localStorage.removeItem('email')
    }
    if (!await this._submit()) {
      return
    }
    try {
      await this.login({ email: this.model.email.toLowerCase(), password: this.model.password })
    } catch (e) {
      this.$message.error('Username or password are incorrect.')
      return
    }
    await this.$router.push(`/organizations/${this.currentUserOrganization.id}`)
  }
}

