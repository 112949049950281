























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Protocol, TeamSchema } from '@/generated/sdk'
import { Action, Getter } from 'vuex-class'
import EmailSelector from '@/components/EmailSelector.vue'
import { cloneDeep } from 'lodash'
import { ADMIN_ROLE } from '@/store/organizations'
@Component({
  components: { EmailSelector },
})
export default class TeamEditDialog extends Vue {
  @Prop()
  visible: boolean

  @Getter team
  @Getter organizationToEdit
  @Getter me
  @Getter roles
  @Getter('isChildOrganization') isChild
  @Action isChildOrganization

  model: TeamSchema = null

  $refs: {
    emailSelector: any;
  }

  @Watch('organizationToEdit', { deep: true })
  onOrganizationChange(org) {
    if (org.id != null) {
      this.checkChildOrganization()
    }
  }

  @Watch('team', { deep: true })
  onTeamChange(team) {
    this.model = cloneDeep(team)
  }

  get dialogVisible() {
    return this.visible
  }

  set dialogVisible(val) {
    this.cancel()
  }

  get protocols() {
    return Protocol
  }

  async checkChildOrganization() {
    await this.isChildOrganization({ parentId: this.me.organization.id, childId: this.organizationToEdit.id })
  }

  get isOwnTeam() {
    return this.organizationToEdit.id === this.me.organization.id
  }

  get isAdmin() {
    return this.roles.includes(ADMIN_ROLE)
  }

  cancel() {
    this.$emit('update:visible', false)
  }

  async submit(input) {
    const preCheck = await this.$refs.emailSelector.checkBeforeSubmit()
    if (!preCheck) {
      return
    }
    this.$emit('submit', input)
    this.$refs.emailSelector.reset()
  }
}

