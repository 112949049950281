
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { OrganizationType } from '@/generated/sdk'
import { fromUnixTime } from 'date-fns'
import { formatDate } from '@/utils'
import { Getter, Action, Mutation } from 'vuex-class'
import { childMap } from '@/store/organizations'
import SearchBar from '@/components/SearchBar.vue'
@Component({
  components: { SearchBar },
})
export default class OrganizationsTable extends Vue {
  @Getter organization
  @Action getOrganizationList
  @Mutation('breadcrumbId') setBreadcrumbId

  @Prop() id: string;

  loading = true

  query = ''
  page = 1

  @Watch('id')
  onNewId() {
    this.page = 1
  }

  @Watch('id')
  @Watch('page')
  onNewPage() {
    this.fetchData()
  }

  @Action deleteOrganizationFromOrganization

  created() {
    this.fetchData()
  }

  get addButtonLabel(): string {
    const organizationType = childMap[this.organization.type].toLowerCase()
    return this.$t('general.add') + ' ' + (this.$t('general.' + organizationType) as string).toLowerCase()
  }

  get isNextButtonDisabled() {
    return this.organization?.organizations == null || this.organization.organizations.length !== 25
  }

  async fetchData() {
    await this.getOrganizationList({
      input: { id: this.id },
      queryArgs: {
        size: 25,
        page: this.page,
        ...(this.query !== '' && { queryString: this.query }),
      },
    })
    if (this.organization.type === 'ORGANIZATION') {
      await this.$router.push('/')
      return
    }
    this.setBreadcrumbId(this.organization.id)
    this.loading = false
  }

  formatDate(date) {
    return formatDate(fromUnixTime(date / 1000), 'dd-MM-yyyy')
  }

  async addOrganization() {
    await this.$router.push(`/organizations/new/${this.id}`)
  }

  async editOrganization(id) {
    const organizationToEdit = this.organization.organizations.find(o => o.id === id)
    await this.$router.push(`/organizations/${organizationToEdit.id}/details`)
  }

  async deleteOrganization(id) {
    try {
      await this.$confirm('Are you sure you want to remove this team?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
      await this.deleteOrganizationFromOrganization(id)
    } catch (error) {}
  }

  deactivateOrganization(id) {
    console.log('deactivating organization', id)
  }

  rowClick(input) {
    const childRoute = this.organization.type !== OrganizationType.Reseller ? '' : '/details'
    const route = `/organizations/${input.id}${childRoute}`
    this.$router.push(route)
  }

  onSearch() {
    this.page = 1
    this.fetchData()
  }
}

