import { Vue } from 'vue-property-decorator'
import Vuex, { ModuleTree } from 'vuex'

import users from './users'
import organizations from './organizations'
import teams from './teams'
import navigation from './navigation'
import notification from './notification'
import roles from './roles'
import vpnServer from './vpnServer'

Vue.use(Vuex)

const modules = {
  users,
  organizations,
  teams,
  navigation,
  notification,
  roles,
  vpnServer,
}

export default new Vuex.Store({
  modules,
})
