































































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import ServerFilesDropdown from '@/components/ServerFilesDropdown.vue'
import LegacyToken from '@/components/LegacyToken.vue'
@Component({
  components: { LegacyToken, ServerFilesDropdown },
})
export default class ManualSettings extends Vue {
  @Getter token
}
