<template>
  <div>
    <h2>
      {{ $t('downloads.appsTab.questionsTitle') }}
    </h2>
    <div>
      <span class="gb-l-faq-link">
        <a href="https://portal.goosevpn.com/support.php">
          <b>{{ $t('downloads.appsTab.faqLink') }} </b>
        </a>
      </span>
      <span class="gb-l-faq-link">
        <a href="https://portal.goosevpn.com/support.php">
          <b>{{ $t('downloads.appsTab.manualsLink') }} </b>
        </a>
      </span>
      <span class="gb-l-email-support">
        {{ $t('downloads.appsTab.contactSupport') }}
        <a class="gb-l-mail-faq" href="mailto: support@goosevpn.com">
          support@goosevnpm.com
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Faq extends Vue { }
</script>

<style scoped lang="scss">
@import "~@/scss/vars";

h2 {
  color: $--color-primary;
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: bold;
}

.gb-l-faq-link {
  display:block;
  a {
    color: $black-ish-1;
  }
}

.gb-l-mail-faq {
  color: $black-ish-1;
  text-decoration: underline;
}

</style>
