



























































import { Component, Vue } from 'vue-property-decorator'
import LegacyToken from '@/components/LegacyToken.vue'
@Component({
  components: { LegacyToken },
})
export default class RouterDownloads extends Vue {
  name = 'RouterDownloads'
}
