<template>
  <div class="gb-l-token">
    <span class="gb-l-token-text">
      <b>{{ $t('downloads.yourToken') }} </b><br>
      {{ legacyToken == null ? 'Loading token...' : legacyToken }}
    </span>
  </div>
</template>

<script>
import { Action, Getter } from 'vuex-class'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class LegacyToken extends Vue {
  name = 'LegacyToken'

  @Action loadLegacyToken
  @Getter legacyToken

  created() {
    console.log('tets')
    this.loadLegacyToken()
  }
}
</script>

<style scoped>

.gb-l-token {
  position: relative;
  text-align: center;
  border: 1px solid #FC6B36;
  border-radius: 12px;
  height: 153px;
  width: 99%;
}

.gb-l-token-text {
  position: relative;
  top: 35%;
}

</style>
